import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Wrapper from './components/common/Wrapper';
import ErrorFallback from './components/common/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import ScrollToTop from './components/common/scrollToTop';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../src/utils/firebaseConfig';
import { setupInterceptors } from './redux/api';

const root = ReactDOM.createRoot(document.getElementById('root'));
initializeApp(firebaseConfig);
setupInterceptors();

root.render(
    <>
        <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Provider store={store}>
                    <Wrapper>
                        <ScrollToTop />
                        <App />
                    </Wrapper>
                </Provider>
            </ErrorBoundary>
        </BrowserRouter>
    </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
