/* eslint-disable react/no-unknown-property */
/* eslint-disable */
import React, { useState, useRef } from 'react';
import Loader from '../../common/Loader';
import { fetchLoginUser } from '../../../redux/actions/user/userLogin';
import { fetchUserInfo } from '../../../redux/actions/user/userInfo';
import Toastify from '../../common/Toastify';
import { ErrorMSG } from '../../../utils/utils';
import {
    ERROR_TOASTIFY_TYPE,
    OTP_VALIDATE_SUCCESS,
    OTP_VALIDATION_FAILURE,
    SUCCESS_TOASTIFY_TYPE,
    WARNING_TOASTIFY_TYPE,
} from '../../../utils/enum';
import { useNavigate } from 'react-router-dom';
import { initialValueLoginEmail, initialValueLoginPhone } from '../../../utils/initialValues';
import {
    validationSchemaForLoginEmail,
    validationSchemaForLoginPhone,
} from '../../../utils/validation';
import OtpInput from 'react-otp-input';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import google from '../../../assets/images/google.png';
import facebook from '../../../assets/images/facebook.png';
import apple from '../../../assets/images/apple.png';
import './Login.css';
import { setItem } from '../../../utils/utils';
import nextImag from '../../../assets/images/next.png';
import { updateUserProfile } from '../../../redux/actions/user/updateUserInfo';
import * as Yup from 'yup';

const Login = ({ intl, setShowLogin }) => {
    const [isOtp, setisOtp] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const otpButtonRef = useRef();
    const [blockInbutton, setBlockInbutton] = useState(false);
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const [isEmailNull, setIsEmailNull] = useState(false);
    const [error, setError] = useState('');
    const formRef = useRef(null);
    const navigate = useNavigate();
    var interval;

    // const email = intl.formatMessage({
    //     id: 'Place_holder.Enter Your Email Address',
    //     defaultMessage: 'Enter Your Email Address',
    // });
    const phone = intl.formatMessage({
        id: 'Place_holder.Enter Your Phone Number',
        defaultMessage: 'Enter Your Phone Number',
    });
    const dispatch = useDispatch();
    const [inputChange, setInputChange] = useState(false);
    // const changeField = () => {
    //     setInputChange(!inputChange);
    // };

    const [otpValue, setOtpValue] = useState('');
    const otpHandleChange = (value) => {
        setOtpValue(value);
        if (value.length === 6) otpButtonRef.current.focus();
    };
    const {
        countryData,
        loginLoading,
        loginSuccessData,
        // loginError
    } = useSelector((state) => ({
        countryData: state?.countryData?.payload?.data,
        loginLoading: state?.loginUser?.loading,
        loginSuccessData: state?.loginUser?.payload?.data,
        loginError: state?.loginUser?.error,
    }));

    const handleFormSubmit = (values) => {
        const formData = new FormData();
        formData.append('email', values.email);
        formData.append('name', values.name);
        setItem('token', loginSuccessData[0].token);
        setItem('refresh_token', loginSuccessData[0].refresh_token);
        dispatch(updateUserProfile(formData)).then((res) => {
            if (res.status === 200) {
                Toastify(OTP_VALIDATE_SUCCESS, SUCCESS_TOASTIFY_TYPE);
                navigate('/landingPage');
                dispatch(fetchUserInfo());
            }
        });
    };

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Name is required')
            .matches(/^[a-zA-Z\s]+$/, 'Name can only contain letters and spaces'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
    });

    useEffect(() => {
        (() => {
            if (isOtpSent) {
                interval = setInterval(() => {
                    setBlockInbutton(false);
                    if (seconds > 0) {
                        setSeconds(seconds - 1);
                    }
                    if (seconds === 0) {
                        if (minutes === 0) {
                            clearInterval(interval);
                            setBlockInbutton(true);
                        } else {
                            setSeconds(59);
                            setMinutes(minutes - 1);
                        }
                    }
                }, 1000);
            }
        })();

        return () => {
            clearInterval(interval);
        };
    }, [seconds, isOtpSent]);

    const onSubmitLogin = (values) => {
        const authentication = getAuth();
        setIsOtpSent(false);

        if (!window.recaptchaVerifier) {
            const configureCaptcha = () => {
                const auth = getAuth();
                window.recaptchaVerifier = new RecaptchaVerifier(
                    'sign-in-button',
                    {
                        size: 'invisible',
                        callback: (response) => {
                            return response;
                        },
                        defaultCountry: 'IN',
                    },
                    auth,
                );
            };
            configureCaptcha();
        }
        window.recaptchaVerifier.render();
        const appVerifier = window.recaptchaVerifier;
        const countryCode = values?.countryCode;
        const code = countryCode.split(',');
        const phoneNumber = `+${code[0]}${values?.phone}`;
        signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
            .then((response) => {
                setisOtp(true);
                setIsOtpSent(true);
                isOtp && setSeconds(59);
                return (window.confirmationResult = response);
            })
            .catch((error) => {
                setError(error.code);
            });
    };

    const otpLoginSubmitButton = (data) => {
        window.confirmationResult
            .confirm(data)
            .then(async (result) => {
                const values = formRef.current.values;
                const country = values?.countryCode?.split(',');
                const phoneData = {
                    value: values?.phone,
                    language_id: 1,
                    country_code: country?.[0],
                    short_code: country?.[1],
                };
                const emailData = {
                    value: values?.email,
                    language_id: 1,
                };

                const apiData = inputChange ? emailData : phoneData;
                const loginRes = await dispatch(fetchLoginUser(apiData));
                console.log('🚀 ~ file: Login.jsx:160 ~ .then ~ loginRes:', loginRes);
                if (loginRes?.data[0]?.email === null) {
                    // setShowLogin(true);
                    setIsEmailNull(true);
                }
                if (loginRes?.data[0]?.email !== null) {
                    Toastify(OTP_VALIDATE_SUCCESS, SUCCESS_TOASTIFY_TYPE);
                    setItem('token', loginRes?.data?.[0]?.token);
                    setItem('refresh_token', loginRes?.data?.[0]?.refresh_token);
                    setShowLogin(false);
                    dispatch(fetchUserInfo());
                    navigate('/landingPage');
                }

                if (loginRes?.error) {
                    Toastify(OTP_VALIDATION_FAILURE, ERROR_TOASTIFY_TYPE);
                }
                return result;
            })
            .catch((error) => {
                if (error.code === 'auth/invalid-verification-code') {
                    Toastify('Invalid OTP', ERROR_TOASTIFY_TYPE);
                }
            });
    };

    return (
        <div>
            {loginLoading ? (
                <Loader loading={loginLoading} />
            ) : (
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {/* <FormattedMessage
                                id="Home_page.Login"
                                defaultMessage={!isEmailNull ? 'Signup' : 'Login'}
                            /> */}
                            {!isEmailNull ? 'Login' : 'SignUp'}
                        </h5>
                        <button
                            href="/"
                            type="button"
                            className="close"
                            onClick={() => setShowLogin(false)}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {!isEmailNull ? (
                            <>
                                {/* <div>
                                    <a
                                        href="/"
                                        type="button"
                                        className="btn btn-primary PopupBtnLogin"
                                        data-dismiss="modal"
                                    >
                                        <img src={google} className="PopupBtnWidth" alt="" />
                                        <FormattedMessage
                                            id="Home_page.Continue with google"
                                            defaultMessage="Continue with google"
                                        />
                                    </a>
                                </div> */}
                                {/* <div className="mt-3">
                                    <a
                                        href="/"
                                        type="button"
                                        className="btn BtnFacebook PopupBtnLogin"
                                        data-dismiss="modal"
                                    >
                                        <img src={facebook} className="PopupBtnWidth" alt="" />
                                        <FormattedMessage
                                            id="Home_page.Continue with facebook"
                                            defaultMessage="Continue with facebook"
                                        />
                                    </a>
                                </div> */}
                                {/* <div className="mt-3">
                                    <a
                                        href="/"
                                        type="button"
                                        className="btn BtnApple PopupBtnLogin"
                                        data-dismiss="modal"
                                    >
                                        <img src={apple} className="PopupBtnWidth" alt="" />
                                        <FormattedMessage
                                            id="Home_page.Continue with Apple"
                                            defaultMessage="Continue with Apple"
                                        />
                                    </a>
                                </div> */}
                                {/* <div
                                    style={{
                                        float: 'left',
                                        width: '44%',
                                        marginTop: 6,
                                    }}
                                >
                                    <hr />
                                </div>
                                <div
                                    style={{
                                        float: 'right',
                                        width: '44%',
                                        marginTop: 6,
                                    }}
                                >
                                    <hr />
                                </div>
                                <span className="LineHeightDivider TextColor">
                                    <FormattedMessage id="Home_page.Or" defaultMessage="Or" />
                                </span> */}
                            </>
                        ) : (
                            ''
                        )}

                        <Formik
                            initialValues={
                                inputChange ? initialValueLoginEmail : initialValueLoginPhone
                            }
                            innerRef={formRef}
                            onSubmit={onSubmitLogin}
                            validationSchema={
                                inputChange
                                    ? validationSchemaForLoginEmail
                                    : validationSchemaForLoginPhone
                            }
                            enableReinitialize={true}
                        >
                            {() => {
                                return !isEmailNull ? (
                                    <Form>
                                        <div id="sign-in-button" />
                                        <div className="form-group mt-3 mb-0">
                                            <>
                                                <label htmlFor="input" className="TextColor">
                                                    <FormattedMessage
                                                        id="Home_page.Phone Number"
                                                        defaultMessage="Phone Number"
                                                    />
                                                </label>
                                                <div className="d-flex direction-row">
                                                    <Field
                                                        as="select"
                                                        className="form-control col-3  scrollable-menu"
                                                        name="countryCode"
                                                    >
                                                        {countryData &&
                                                            countryData.map((data) => {
                                                                const valueOfInput = [
                                                                    data?.country_code,
                                                                    data?.short_code,
                                                                ];
                                                                return (
                                                                    <option
                                                                        key={data?.id}
                                                                        value={valueOfInput}
                                                                    >
                                                                        {`+${data?.country_code}-
                                                                                            ${data?.name}`}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Field>
                                                    <Field
                                                        type="number"
                                                        className="form-control NewFormPhone"
                                                        id="phone"
                                                        name="phone"
                                                        aria-describedby="emailHelp"
                                                        placeholder={phone}
                                                    />
                                                </div>
                                                <div className="Eroorcode Width25">
                                                    {ErrorMSG('countryCode')}
                                                </div>
                                                <div className="Eroorcode">{ErrorMSG('phone')}</div>
                                            </>
                                        </div>
                                        <div className="mt-3 ModalHomeFooter">
                                            <button
                                                type="submit"
                                                className="btn btn-danger WidthLoginBtn"
                                                disabled={
                                                    seconds > 0 || (minutes > 0 && blockInbutton)
                                                }
                                            >
                                                <FormattedMessage
                                                    id={
                                                        isOtp
                                                            ? 'Home_page.ResendOTP'
                                                            : 'Home_page.SendOTP'
                                                    }
                                                    defaultMessage="Login"
                                                />
                                            </button>
                                        </div>

                                        {error === 'auth/too-many-requests' ? (
                                            <div className="shrink">
                                                due to some suspicious activity your number is
                                                blocked for some time please try after some time
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {isOtpSent && (
                                            <>
                                                <div className="row justify-content-center align-items-center ">
                                                    {seconds > 0 || minutes > 0 ? (
                                                        <p>
                                                            Resend OTP in:{' '}
                                                            {minutes < 10 ? `0${minutes}` : minutes}
                                                            :
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) : (
                                                        <p>Didn&apos;t recieve code?</p>
                                                    )}
                                                    <OtpInput
                                                        containerStyle={{
                                                            justifyContent: 'Center',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            paddingRight: 20,
                                                            paddingLeft: 20,
                                                        }}
                                                        inputStyle={{
                                                            width: '80%',
                                                            padding: 8,
                                                        }}
                                                        shouldAutoFocus={true}
                                                        value={otpValue}
                                                        onChange={otpHandleChange}
                                                        numInputs={6}
                                                        // separator={<span>-</span>}
                                                        isInputNum={true}
                                                    />
                                                </div>

                                                <div className="modal-footer ModalHomeFooter">
                                                    <button
                                                        type="button"
                                                        ref={otpButtonRef}
                                                        className={
                                                            otpValue.length === 6
                                                                ? 'btn btn-danger pull-left'
                                                                : 'btn btn-danger pull-left disabled'
                                                        }
                                                        onClick={() =>
                                                            otpLoginSubmitButton(otpValue)
                                                        }
                                                    >
                                                        <FormattedMessage
                                                            id="common_page.Continue"
                                                            defaultMessage="Continue"
                                                        />
                                                        <img
                                                            src={nextImag}
                                                            alt=""
                                                            className="NextIconModal"
                                                        />
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                ) : (
                                    <Formik
                                        initialValues={{ name: '', email: '' }}
                                        onSubmit={handleFormSubmit}
                                        validationSchema={validationSchema}
                                    >
                                        <Form>
                                            <div className="form-group mt-3 mb-0">
                                                <div id="sign-in-button" />
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label
                                                            htmlFor="exampleInputEmail1"
                                                            className="TextColor"
                                                        >
                                                            <FormattedMessage
                                                                id="Home_page.Your Name"
                                                                defaultMessage="Your name"
                                                            />
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Enter your name"
                                                        />
                                                        {ErrorMSG('name')}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-12">
                                                        <label
                                                            htmlFor="exampleInputEmail1"
                                                            className="TextColor"
                                                        >
                                                            <FormattedMessage
                                                                id="Home_page.Email Address"
                                                                defaultMessage="Email Address"
                                                            />
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Enter your Email"
                                                        />
                                                        {ErrorMSG('email')}
                                                    </div>
                                                </div>
                                                <div className=" mt-3 ModalHomeFooter">
                                                    <div className="mt-3 ModalHomeFooter">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-danger WidthLoginBtn"
                                                        >
                                                            <FormattedMessage
                                                                id={'Home_page.SignUp'}
                                                                defaultMessage="SignUp"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            )}
        </div>
    );
};

export default injectIntl(Login);
/* eslint-disable */
