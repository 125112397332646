import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fetchCustomerSupport } from '../../../redux/actions/user/customerSupport';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import addPhoto from '../../../assets/images/add-photo.png';
import './CustomerSupport.css';
const CustomerSupport = () => {
    const dispatch = useDispatch();
    const optionValue = [
        {
            lable: (
                <FormattedMessage id="UserProfile_page.Food issue" defaultMessage="Food issue" />
            ),
            value: 'Food issue',
        },
        {
            lable: (
                <FormattedMessage id="UserProfile_page.Order issue" defaultMessage="Order issue" />
            ),
            value: 'Order issue',
        },
        {
            lable: (
                <FormattedMessage
                    id="UserProfile_page.Delivery issue"
                    defaultMessage="Delivery issue"
                />
            ),
            value: 'Delivery issue',
        },
        {
            lable: (
                <FormattedMessage
                    id="UserProfile_page.Payment issue"
                    defaultMessage="Payment issue"
                />
            ),
            value: 'Payment issue',
        },
        {
            lable: (
                <FormattedMessage
                    id="UserProfile_page.Refund issue"
                    defaultMessage="Refund issue"
                />
            ),
            value: 'Refund issue',
        },
        {
            lable: (
                <FormattedMessage
                    id="UserProfile_page.Order Itemes issue"
                    defaultMessage="Order Itemes issue"
                />
            ),
            value: 'Order Itemes issue',
        },
        {
            lable: (
                <FormattedMessage
                    id="UserProfile_page.Packaging issue"
                    defaultMessage="Packaging issue"
                />
            ),
            value: 'Packaging issue',
        },
        {
            lable: (
                <FormattedMessage
                    id="UserProfile_page.Technical issue"
                    defaultMessage="Technical issue"
                />
            ),
            value: 'Technical issue',
        },
        {
            lable: (
                <FormattedMessage
                    id="UserProfile_page.Coupon issue"
                    defaultMessage="Coupon issue"
                />
            ),
            value: 'Coupon issue',
        },
        {
            lable: (
                <FormattedMessage id="UserProfile_page.Other issue" defaultMessage="Other issue" />
            ),
            value: 'Other issue',
        },
    ];
    const formik = useFormik({
        initialValues: {
            file: null,
            type: 'Food issue',
            comments: '',
        },
        validationSchema: Yup.object({
            file: Yup.mixed().required('File is required'),
            type: Yup.string().required('Please Select Type'),
            comments: Yup.string().required('Please add your comments'),
        }),
        onSubmit: () => {
            handleSubmit();
        },
    });

    const handleSubmit = () => {
        const value = formik.getFieldProps();

        const imag = value?.value?.file[0];
        const formas = new FormData();
        formas.append('type', value?.value?.type);
        formas.append('comments', value?.value?.comments);
        [...imag].map((d) => {
            formas.append('image[]', d, d.name);
        });
        dispatch(fetchCustomerSupport(formas));
    };

    return (
        <>
            <div
                className="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
            >
                <h2 className="respmargtopprof RespMargTop">
                    <FormattedMessage
                        id="UserProfile_page.Customer Support"
                        defaultMessage="Customer Support"
                    />
                </h2>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card ProfileWidthcard mt-4">
                            <div className="card-body ProfileCardBody text-center">
                                <div>
                                    <img
                                        src={addPhoto}
                                        multiple
                                        className="IconAddress mt-4"
                                        alt="addPhoto"
                                    />
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="exampleFormControlFile1"
                                        name="file"
                                        onChange={(e) =>
                                            formik.setFieldValue('file', [e.currentTarget.files])
                                        }
                                        multiple="multiple"
                                        accept="image/*"
                                    />
                                </div>
                                {formik.errors.file && formik.touched.file && (
                                    <p style={{ color: 'red' }}>{formik.errors.file}</p>
                                )}
                            </div>
                        </div>

                        <form className="mt-4">
                            <div className="form-group">
                                <h5 htmlFor="exampleInputEmail1">
                                    <FormattedMessage
                                        id="UserProfile_page.Issue Type"
                                        defaultMessage="Issue Type"
                                    />
                                </h5>
                                <select
                                    name="type"
                                    className="form-control mt-3"
                                    onChange={formik.handleChange}
                                    value={formik.values.type}
                                    onBlur={formik.handleBlur}
                                >
                                    {optionValue.map((option) => {
                                        return <option key={option.value}>{option.lable}</option>;
                                    })}
                                </select>
                                {formik.errors.type && formik.touched.type && (
                                    <p style={{ color: 'red' }}>{formik.errors.type}</p>
                                )}
                            </div>
                            <div className="form-group mt-4">
                                <h5 htmlFor="exampleInputEmail1">
                                    <FormattedMessage
                                        id="UserProfile_page.Add Comment"
                                        defaultMessage="Add Comment"
                                    />
                                </h5>
                                <textarea
                                    className="form-control mt-3"
                                    aria-label="With textarea"
                                    name="comments"
                                    rows={5}
                                    onChange={formik.handleChange}
                                    value={formik.values.comments}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.comments && formik.touched.comments && (
                                    <p style={{ color: 'red' }}>{formik.errors.comments}</p>
                                )}
                            </div>
                            <div className="mb-5">
                                <button onClick={formik.handleSubmit} className="btn btn-danger">
                                    <FormattedMessage
                                        id="UserProfile_page.Send"
                                        defaultMessage="Send"
                                    />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerSupport;
