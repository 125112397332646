/* eslint-disable */
const firebaseConfig = {
    apiKey: 'AIzaSyDcjtGb2jSVKXsUjxVAcJx6hboHbUe6fqI',
    authDomain: 'tastes-on-way-user.firebaseapp.com',
    projectId: 'tastes-on-way-user',
    storageBucket: 'tastes-on-way-user.appspot.com',
    messagingSenderId: '1083213576758',
    appId: '1:1083213576758:web:334bdeb4bd159433667697',
    measurementId: 'G-8XFJSGB1V9',
};
export default firebaseConfig;
/* eslint-disable */


