import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Login from '../login/Login';
// import SignUp from '../signUp/SignUp';
import image from '../../../assets/images/Tastes_on_way.png';
import './HomeHeader.css';
import { useContext } from 'react';
import { Context } from '../../common/Wrapper';
import { Modal } from 'react-bootstrap';

const HomeHeader = () => {
    const context = useContext(Context);
    const [showLogin, setShowLogin] = useState(true);
    // const [showSignUp, setShowSignUp] = useState(false);
    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-light NavHead NavbackWhite">
                <div className="container">
                    <a href="/" className="navbar-brand BrandLogo">
                        <img src={image} alt="" className="ImgWidthFix img-fluid" />
                    </a>
                    <ul className="navbar-nav DisplayHideResp">
                        <li className="nav-item">
                            <button
                                className="nav-link NavLinkColor border-0 bg-transparent"
                                onClick={() => setShowLogin(true)}
                            >
                                <FormattedMessage id="Home_page.Login" defaultMessage="Login" />
                            </button>

                            <Modal show={showLogin} onHide={() => setShowLogin(false)}>
                                <Login setShowLogin={setShowLogin} />
                            </Modal>
                        </li>
                        {/* <li className="nav-item">
                            <button
                                className="nav-link NavLinkColor border-0 bg-transparent"
                                onClick={() => setShowSignUp(true)}
                            >
                                <FormattedMessage id="Home_page.SignUp" defaultMessage="Signup" />
                            </button>

                            <Modal show={showSignUp} onHide={() => setShowSignUp(false)}>
                                <SignUp setShowSignUp={setShowSignUp} />
                            </Modal>
                        </li> */}
                    </ul>
                </div>
                <select
                    className="form-control col-md-1"
                    value={context.locale}
                    onChange={context.selectLang}
                >
                    <option value="en">English</option>
                    <option value="hi">हिंदी</option>
                    <option value="gj">ગુજરાતી</option>
                </select>
            </nav>
        </>
    );
};

export default HomeHeader;
