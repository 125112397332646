import React, { useState } from 'react';
import OrderDetails from '../../common/OrderDetails';
import ErrorFallback from '../../common/ErrorFallback';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import cartImg from '../../../assets/images/add-items-cart.png';
import trackorder from '../../../assets/images/trackorder.png';
import rupee from '../../../assets/images/rupeeiconblack.png';
import { useEffect } from 'react';
import { fetchUserOrderHistory } from '../../../redux/actions/user/userOrderHistory';
// import TrackOrderDetails from '../TrackOrderDetails';
import { clearOrderDetails } from '../../../services/orederDetails/OrderDetails';
import { Modal } from 'react-bootstrap';
import RefrralCode from '../RefrralCode/RefrralCode';
import { getItem } from '../../../utils/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import TrackOrder from '../TrackOrder';
const YourOrder = () => {
    const [searchValue, setSearchValue] = useState('all');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [pages, setPages] = useState(1);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const { userOrderHistory } = useSelector((state) => ({
        userOrderHistory: state?.userOrderHistory?.payload?.data,
    }));
    useEffect(() => {
        if (!show) {
            dispatch(clearOrderDetails());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    const lat = getItem('lat');
    const long = getItem('long');
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(userOrderHistory?.total / 10); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        const apiData = {
            date_filter: searchValue,
            latitude: lat,
            longitude: long,
            radius: '6',
            page_size: '10',
        };
        dispatch(fetchUserOrderHistory(apiData, pages));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, pages]);

    const handleChange = (e) => {
        setSearchValue(e.target.value);
    };

    const [searchPerams] = useSearchParams();

    useEffect(() => {
        const OrderIdQueryPerams = searchPerams.get('order_id');
        if (OrderIdQueryPerams?.length > 0) {
            const result = userOrderHistory?.data.filter((data) => {
                return data.id == Number(OrderIdQueryPerams);
            });
            setSelectedOrder(result?.[0]);
            setShow(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userOrderHistory]);

    return (
        <div
            className="tab-pane fade show active"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
        >
            <h2 className="respmargtopprof RespMargTop">
                <FormattedMessage id="UserProfile_page.Your Order" defaultMessage="Your Order" />
            </h2>
            <RefrralCode />

            <div className="input-group mt-4">
                <select onChange={handleChange} className="form-control">
                    <option value="all">
                        <FormattedMessage id="UserProfile_page.All" defaultMessage="All" />
                    </option>
                    <option value="week">
                        <FormattedMessage
                            id="UserProfile_page.This Week"
                            defaultMessage="This Week"
                        />
                    </option>
                    <option value="month">
                        <FormattedMessage
                            id="UserProfile_page.This Month"
                            defaultMessage="This Month"
                        />
                    </option>
                </select>
            </div>

            <div className="side-bar">
                {userOrderHistory?.data?.length == 0 || userOrderHistory == undefined ? (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: '100%' }}
                    >
                        <img src={cartImg} alt="profiledeliciousdosa" />
                        <div className="d-flex flex-column mt-2">
                            <FormattedMessage
                                id="UserProfile_page.No Order Available or Allow Location!"
                                defaultMessage="No Order Available or Allow Location!"
                            />
                            <div className="d-flex justify-content-center mt-2">
                                <button
                                    className="btn  btn-sm"
                                    style={{ backgroundColor: '#dc3545', color: 'white' }}
                                    onClick={() => navigate('/landingPage')}
                                >
                                    <FormattedMessage
                                        id="UserProfile_page.Start an order"
                                        defaultMessage="Start an order"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {' '}
                        {userOrderHistory?.data?.map((data) => (
                            <div className="row" key={data.id}>
                                <div className="col-lg-12">
                                    <div className="card mt-4  ">
                                        <h5 className="card-header Boxcardborderbottom">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <img
                                                        src={data.business_owner.avatar}
                                                        className="WidthProfImg"
                                                        alt="profiledeliciousdosa"
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <h5 className="HeadProfBox">
                                                        {data?.business_owner_address?.office_name}
                                                    </h5>
                                                    <div className="NormalTextProf">
                                                        {data?.business_owner_address?.address}
                                                    </div>
                                                    <div className="NormalTextProf">
                                                        {Math.round(
                                                            data.user_address.distance * 100,
                                                        ) / 100}
                                                        <FormattedMessage
                                                            id="UserProfile_page.KM"
                                                            defaultMessage="KM"
                                                        />
                                                    </div>
                                                </div>

                                                {data.order_status == 'Delivered' ||
                                                data.order_status == 'Cancelled' ? (
                                                    <div className="col-lg-6 d-flex justify-content-end   ">
                                                        <div className="btn btn-default DivRightCancelled">
                                                            {data.order_status}
                                                        </div>
                                                        <ErrorBoundary
                                                            FallbackComponent={ErrorFallback}
                                                        >
                                                            <OrderDetails data={data} />
                                                        </ErrorBoundary>
                                                    </div>
                                                ) : (
                                                    <div className="col-lg-6">
                                                        <div className="BadgeMainDiv">
                                                            <button
                                                                className="badge TrackOrder border-0"
                                                                data-toggle="modal"
                                                                data-target="#exampleModaltrack"
                                                                onClick={() => {
                                                                    setShow(true);
                                                                    setSelectedOrder(data);
                                                                }}
                                                            >
                                                                <FormattedMessage
                                                                    id="UserProfile_page.Track Order"
                                                                    defaultMessage="Track Order"
                                                                />{' '}
                                                                <img
                                                                    src={trackorder}
                                                                    alt="trackorder"
                                                                />
                                                            </button>

                                                            {selectedOrder?.id == data?.id &&
                                                                show && (
                                                                    <Modal
                                                                        show={show}
                                                                        onHide={() =>
                                                                            setShow(!show)
                                                                        }
                                                                        animation={true}
                                                                        size="lg"
                                                                    >
                                                                        <div className="modal-header">
                                                                            <h4
                                                                                className="modal-title"
                                                                                id="exampleModalLabel"
                                                                            >
                                                                                <FormattedMessage
                                                                                    id="UserProfile_page.Track Order"
                                                                                    defaultMessage="Track Order"
                                                                                />
                                                                                {/* {data.order_status &&
                                                                                    ` : ${data.order_status.toUpperCase()}`} */}
                                                                            </h4>
                                                                            <button
                                                                                type="button"
                                                                                className="close"
                                                                                data-dismiss="modal"
                                                                                aria-label="Close"
                                                                                onClick={() =>
                                                                                    setShow(false)
                                                                                }
                                                                            >
                                                                                <span aria-hidden="true">
                                                                                    ×
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                        {/* <TrackOrderDetails
                                                                            data={selectedOrder}
                                                                        /> */}
                                                                        <TrackOrder
                                                                            data={selectedOrder}
                                                                        />
                                                                    </Modal>
                                                                )}
                                                        </div>
                                                        <ErrorBoundary
                                                            FallbackComponent={ErrorFallback}
                                                        >
                                                            <OrderDetails data={data} />
                                                        </ErrorBoundary>
                                                    </div>
                                                )}
                                            </div>
                                        </h5>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <span className="TextOverflowCard">
                                                        {data.order_detail[0].quantity} x{' '}
                                                        {data.order_detail[0].menu_items[0].name}
                                                    </span>
                                                    <div className="mt-2 TextColor">
                                                        {moment(
                                                            data.date_for_incoming_order,
                                                            'YYYY-MM-DD',
                                                        ).format('DD-MM-YYYY')}{' '}
                                                        at {data.time_for_incoming_order}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="BadgeMainDiv">
                                                        <span>
                                                            <img
                                                                src={rupee}
                                                                className="RupeeBlackWidth"
                                                                alt="rupee"
                                                            />
                                                            {data.order_final_total}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
            {userOrderHistory?.data?.length == 0 || userOrderHistory == undefined ? (
                <></>
            ) : (
                <nav className="d-flex justify-content-end mb-5 mt-3" aria-label="">
                    <ul className="pagination pagination-sm position-absolute top-0 end-0">
                        <button
                            className="btn  btn-sm"
                            style={{ backgroundColor: '#dc3545', color: 'white' }}
                            onClick={() => setPages(pages > 1 ? pages - 1 : 1)}
                        >
                            <FormattedMessage
                                id="UserProfile_page.Previous"
                                defaultMessage="Previous"
                            />
                        </button>
                        {pageNumbers.map((number) => {
                            return pages === number ? (
                                <li className="page-item active" key={number}>
                                    <button
                                        aria-current={pages}
                                        className="page-link"
                                        style={
                                            number
                                                ? {
                                                      backgroundColor: '#ff0000',
                                                      color: 'white',
                                                      borderColor: '#ff0000',
                                                  }
                                                : { backgroundColor: 'white', color: 'black' }
                                        }
                                        onClick={() => setPages(number)}
                                    >
                                        {number}
                                    </button>
                                </li>
                            ) : (
                                <li className="page-item" key={number}>
                                    <button
                                        aria-current={pages}
                                        className="page-link"
                                        onClick={() => setPages(number)}
                                        style={
                                            number
                                                ? { backgroundColor: 'white', color: 'black' }
                                                : { backgroundColor: '#dc3545', color: 'white' }
                                        }
                                    >
                                        {number}
                                    </button>
                                </li>
                            );
                        })}
                        <button
                            className="btn btn-sm"
                            style={{ backgroundColor: '#dc3545', color: 'white' }}
                            onClick={() => setPages(pages < pageNumbers.length ? pages + 1 : pages)}
                        >
                            <FormattedMessage id="UserProfile_page.Next" defaultMessage="Next" />
                        </button>
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default YourOrder;
