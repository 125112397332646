import axios from 'axios';
import config from '../utils/config';
import string from '../utils/string';
import { getItem, setItem } from '../utils/utils';

const { API_URL } = config;

export const apiHeaders = async () => {
    const token = await getItem('token');
    if (token) {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Language: 'gu, hi, en',
            },
        };
    }
    return {
        headers: {
            'Content-Type': 'application/json',
            Language: 'gu, hi, en',
        },
    };
};

export const getApi = async (url) => axios.get(`${API_URL}${url}`, await apiHeaders());

export const postApi = async (url, apiData) =>
    axios.post(`${API_URL}${url}`, apiData, await apiHeaders());

export const putApi = async (url, apiData) =>
    axios.put(`${API_URL}${url}`, apiData, await apiHeaders());

export const patchApi = async (url, apiData) =>
    axios.patch(`${API_URL}${url}`, apiData, await apiHeaders());

export const deleteApi = async (url) => axios.delete(`${API_URL}${url}`, await apiHeaders());

export const getApiError = (error) =>
    typeof error?.response?.data?.error === 'string'
        ? error.response.data.error
        : string.errors.somethingWrong;

export const setupInterceptors = () => {
    let isRefreshing = false;

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if ([401].includes(error?.response?.status) && !originalRequest._retry) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    const refreshToken = getItem('refresh_token');
                    const data = {
                        refresh_token: refreshToken,
                    };
                    try {
                        const res = await axios.post(`${API_URL}refresh-token`, data);
                        const newAccessToken = res.data.data.original.access_token;
                        setItem('token', newAccessToken);
                        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        originalRequest._retry = true;
                        return axios(originalRequest);
                    } catch (err) {
                        console.error('Error refreshing token:', err);
                        localStorage.clear();
                        return Promise.reject(err);
                    } finally {
                        isRefreshing = false;
                    }
                }
            }
            return Promise.reject(error);
        },
    );
};
