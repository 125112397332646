import {
    CUSTOMER_SUPPORT_REQUEST,
    CUSTOMER_SUPPORT_SUCCESS,
    CUSTOMER_SUPPORT_FAILURE,
} from '../../types/types';

import { postApi } from '../../api';
import {
    CUSTOMER_SUPPORT_DATA_FAIL,
    CUSTOMER_SUPPORT_DATA_LOADING,
    CUSTOMER_SUPPORT_DATA_SUCCESS,
    ERROR_TOASTIFY_TYPE,
    INFO_TOASTIFY_TYPE,
    SUCCESS_TOASTIFY_TYPE,
} from '../../../utils/enum';
import Toastify from '../../../components/common/Toastify';

export const customerSupportRequest = () => ({
    type: CUSTOMER_SUPPORT_REQUEST,
});
export const customerSupportSuccess = (payload) => ({
    type: CUSTOMER_SUPPORT_SUCCESS,
    payload,
});
export const customerSupportFailure = (error) => ({
    type: CUSTOMER_SUPPORT_FAILURE,
    error,
});

export const fetchCustomerSupport = (data) => async (dispatch) => {
    dispatch(customerSupportRequest());
    Toastify(CUSTOMER_SUPPORT_DATA_LOADING, INFO_TOASTIFY_TYPE);
    return postApi(`customer-support-ticket`, data)
        .then((res) => {
            Toastify(CUSTOMER_SUPPORT_DATA_SUCCESS, SUCCESS_TOASTIFY_TYPE);

            dispatch(customerSupportSuccess(res.data));
            return res ?? res?.data ?? res?.data?.responseData ?? null;
        })
        .catch((e) => {
            Toastify(CUSTOMER_SUPPORT_DATA_FAIL, ERROR_TOASTIFY_TYPE);
            dispatch(customerSupportFailure(e));
        });
};
