import React, { useState } from 'react';
import * as yup from 'yup';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { addUserAddress } from '../../../redux/actions/user/addUserAddress';
import { getItem, removeItem, setItem } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import home from '../../../assets/images/home.png';
import addNewAddress from '../../../assets/images/addnewaddress.png';
import cancelPolicy from '../../../assets/images/cancellation-policy-banner.png';
import workImage from '../../../assets/images/work-icon.png';
import otherImage from '../../../assets/images/other-location.png';
import { injectIntl } from 'react-intl';
import './CartAddress.css';
import { FormattedMessage } from 'react-intl';
import { fetchsetUserAddress } from '../../../redux/actions/user/setDeliveryAddress';
import { useEffect } from 'react';
import { clearCityData, fetchCityData } from '../../../redux/actions/countryData/cityData';
import { clearAreaData, fetchAreaData } from '../../../redux/actions/countryData/areaData';
import { fetchStatesData } from '../../../redux/actions/countryData/statesData';
import { useMemo } from 'react';
import { useCallback } from 'react';
const CartAddress = ({ intl }) => {
    const zip = intl.formatMessage({ id: 'Place_holder.380015', defaultMessage: '380015' });
    // const city = intl.formatMessage({ id: 'Place_holder.Ahmedabad', defaultMessage: 'Ahmedabad' });
    const address1 = intl.formatMessage({
        id: 'Place_holder.1006, hospitality exacel',
        defaultMessage: '1006, hospitality exacel',
    });
    const { cityData, areaData, statesData } = useSelector((state) => ({
        statesData: state?.statesData?.payload?.data,
        cityData: state?.cityData?.payload?.data,
        areaData: state?.areaData?.payload?.data,
    }));
    const [selectedState, setSelectedState] = useState(
        statesData?.[0]?.name ? statesData?.[0]?.name : '',
    );
    const options = {
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['establishment'],
    };
    const lat = getItem('lat');
    const long = getItem('long');
    // const addValue = getItem('address');
    // const valueAdd = addValue?.split(',');
    const [show, setShow] = useState(false);
    const [mapShow, setMapShow] = useState(true);
    const [stateId, setStateId] = useState();
    const [areaId, setAreaId] = useState();
    const [address, setAddress] = useState('');
    // const [addressValue, setAddressValue] = useState('');
    const [currentLocation, setCurrentLocation] = useState();
    const dispatch = useDispatch();
    const userAddress = JSON.parse(getItem('userAddress'));
    const handleShow = () => setShow(!show);
    const handleClose = () => {
        setShow(false);
        setMapShow(true);
        setStateId();
        setAreaId();
        dispatch(clearCityData());
        dispatch(clearAreaData());
    };
    const handelDeliverHere = (id) => {
        const apiData = {
            address_id: id,
        };
        dispatch(fetchsetUserAddress(apiData));
    };

    const handelChangeState = (value) => {
        const id = statesData?.find((d) => d.name == value);
        setStateId(id.id);
        setSelectedState(id.id);
    };
    const formik = useFormik({
        initialValues: {
            states: selectedState,
            Address: '',
            Area: areaData?.[0]?.name,
            Landmark: '',
            City: cityData?.[0]?.id,
            Zip: '',
            AddressType: '1',
        },
        validationSchema: yup.object({
            states: yup.string().required('States is required'),
            Address: yup.string().required('Address is Required'),
            Area: yup.string().required('Area is Required'),
            Landmark: yup.string().required('Landmark is Required'),
            City: yup.string().required('City is Required'),
            Zip: yup.string().max(6, 'Please Enter Valid Zip Code').required('Zip is Required'),
            AddressType: yup.string().required('Please Select Address Type'),
        }),
        // enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            const apiData = {
                state_id: selectedState,
                city_id: values.City,
                address: values.Address,
                area: values.Area,
                land_mark: values.Landmark,
                pin_code: values.Zip,
                address_type: values.AddressType,
                latitude: lat,
                longitude: long,
            };
            dispatch(addUserAddress(apiData));
            resetForm();
            handleClose();
            setShow(!show);
            removeItem('address');
        },
    });
    const handelChangeArea = (value) => {
        const id = cityData?.find((d) => d.name == value);
        setAreaId(id?.id);
    };

    useEffect(() => {
        const storedLat = getItem('lat');
        const storedLng = getItem('long');
        if (storedLat && storedLng) {
            setAddress(getItem('address'));
            setCurrentLocation({
                lat: parseFloat(storedLat),
                lng: parseFloat(storedLng),
            });
        } else if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    setItem('lat', position.coords.latitude.toString());
                    setItem('long', position.coords.longitude.toString());
                },
                (error) => {
                    setItem('lat', 23.012);
                    setItem('long', 72.5108);
                    return error;
                },
                {
                    enableHighAccuracy: true,
                },
            );
        }
    }, []);

    const handleDrag = useCallback((event) => {
        const lat = event.latLng.lat().toString();
        const lng = event.latLng.lng().toString();
        setItem('lat', lat);
        setItem('long', lng);
    }, []);

    const handleSelect = useCallback(async (value) => {
        // const ValueAddress = value?.split(',');

        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        // setAddressValue(ValueAddress[0]);
        setAddress(value);
        setCurrentLocation(latLng);
        setItem('lat', latLng.lat.toString());
        setItem('long', latLng.lng.toString());
    }, []);

    const center = useMemo(
        () => ({
            lat: parseFloat(getItem('lat')) || 23.012,
            lng: parseFloat(getItem('long')) || 72.5108,
        }),
        [],
    );

    const MapWithAMarker = useMemo(
        () =>
            withGoogleMap(() => (
                <GoogleMap
                    defaultZoom={17}
                    defaultCenter={
                        currentLocation || {
                            lat: Number(lat) || 23.012,
                            lng: Number(long) || 72.5108,
                        }
                    }
                >
                    <Marker
                        draggable={true}
                        onDrag={handleDrag}
                        position={{
                            lat: Number(lat) || 23.012,
                            lng: Number(long) || 72.5108,
                        }}
                    />
                </GoogleMap>
            )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [center, handleDrag, lat, long],
    );
    useEffect(() => {
        const id = getItem('country_id');
        const apiData = {
            country_id: id,
        };
        dispatch(fetchStatesData(apiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        const state_id = JSON.parse(getItem('statesData'));
        const apiData = {
            state_id: stateId ? stateId : state_id?.[0]?.id,
        };
        dispatch(fetchCityData(apiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateId]);

    useEffect(() => {
        const cityId = JSON.parse(getItem('cityData'));
        const apiData = {
            city_id: areaId ? areaId : cityId?.data?.[0]?.id,
        };

        dispatch(fetchAreaData(apiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areaId, cityData]);
    const handleNext = () => {
        setMapShow(false);
    };
    return (
        <div className="col-lg-8">
            <div className="">
                <div className="DivDeliveryAddress">
                    <div className="DeliciousDosaFont">
                        <FormattedMessage
                            id="Cart_page.Choose a delivery address"
                            defaultMessage="Choose a delivery address"
                        />
                    </div>
                    {userAddress?.data?.length > 1 ? (
                        <div className="TextColor">
                            <FormattedMessage
                                id="Cart_page.Multiple addresses in this location"
                                defaultMessage="Multiple addresses in this location"
                            />
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="row">
                        {userAddress?.data?.length > 0 &&
                            userAddress?.data?.map((d) => {
                                return (
                                    <div className="col-lg-6" key={d.id}>
                                        <div className="col-lg-12 DivBoxAddress">
                                            <img
                                                src={
                                                    d.address_type == 'Home'
                                                        ? home
                                                        : d.address_type == 'Work'
                                                        ? workImage
                                                        : otherImage
                                                }
                                                alt="homeIcon"
                                            />
                                            <span className="HomeAddress">{d.address_type}</span>
                                            <p className="TextAdd AddTextWrap">
                                                {userAddress?.data
                                                    ? `${d.address},${d.area},${d.land_mark},${d.pin_code}.`
                                                    : ''}
                                            </p>
                                            {d.is_delivery_address == 'Yes' ? (
                                                <button
                                                    type="button"
                                                    className="btn btn-danger mt-5 BtnDeliverHere"
                                                    data-dismiss="modal"
                                                    disabled
                                                >
                                                    <FormattedMessage
                                                        id="Cart_page.Delivery Address"
                                                        defaultMessage="Delivery Address"
                                                    />
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-danger mt-5 BtnDeliverHere"
                                                    data-dismiss="modal"
                                                    onClick={() => handelDeliverHere(d.id)}
                                                >
                                                    <FormattedMessage
                                                        id="Cart_page.Deliver Here"
                                                        defaultMessage="Deliver Here"
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}

                        <div className="col-lg-6">
                            <div className="col-lg-12 DivBoxAddress">
                                <img src={addNewAddress} alt="addNewAddress" />
                                <span className="HomeAddress">
                                    <FormattedMessage
                                        id="Cart_page.Add New Address"
                                        defaultMessage="Add New Address"
                                    />
                                </span>
                                <p></p>
                                <button
                                    type="button"
                                    className="btn btn-danger BtnAddnewAdd"
                                    onClick={() => handleShow()}
                                >
                                    <FormattedMessage
                                        id="Cart_page.Add New"
                                        defaultMessage="Add New"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} animation={false}>
                        <div className="modal-content ContentLeft">
                            <div className="modal-header">
                                <h4 className="modal-title" id="exampleModalLabel">
                                    <FormattedMessage
                                        id="Cart_page.Add New Address"
                                        defaultMessage="Add New Address"
                                    />
                                </h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        onClick={() => {
                                            setShow(!show);
                                            handleClose();
                                        }}
                                        aria-hidden="true"
                                    >
                                        ×
                                    </span>
                                </button>
                            </div>
                            {mapShow ? (
                                <div className="p-4">
                                    <MapWithAMarker
                                        containerElement={<div style={{ height: `400px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />
                                    <label className="mt-4" htmlFor="inputAddress">
                                        Search
                                    </label>
                                    <div>
                                        <PlacesAutocomplete
                                            className="placeInput"
                                            value={address}
                                            onChange={setAddress}
                                            onSelect={handleSelect}
                                            searchOptions={options}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                <div>
                                                    <input
                                                        style={{
                                                            marginTop: '5px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            width: '100%',
                                                            height: '40px',
                                                            fontSize: '16px',
                                                            padding: '10px',
                                                            borderRadius: '5px',
                                                            border: '1px solid #ccc',
                                                        }}
                                                        className="placeInput"
                                                        {...getInputProps({
                                                            placeholder: 'Enter your address',
                                                        })}
                                                    />
                                                    <div className="divy">
                                                        {loading ? <div>...loading</div> : null}
                                                        {suggestions.map((suggestion) => {
                                                            const style = {
                                                                backgroundColor: suggestion.active
                                                                    ? '#dc3545'
                                                                    : '#fff',
                                                                borderStyle: 'hidden',
                                                                color: suggestion.active
                                                                    ? '#fff'
                                                                    : 'black',
                                                                padding: '5px',
                                                            };
                                                            return (
                                                                <div key={suggestion.index}>
                                                                    <div
                                                                        className="suggestionInput"
                                                                        {...getSuggestionItemProps(
                                                                            suggestion,
                                                                            {
                                                                                style,
                                                                            },
                                                                        )}
                                                                    >
                                                                        {suggestion.description}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-danger mt-3"
                                        onClick={() => {
                                            handleNext();
                                        }}
                                    >
                                        Next
                                    </button>
                                    <div className="modal-footer ModalHomeFooterProfile" />
                                </div>
                            ) : (
                                <>
                                    {' '}
                                    <div className="modal-body">
                                        <div className="row AddAddressModal">
                                            <div className="col-lg-12">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="form-group">
                                                        <label htmlFor="inputAddress">
                                                            <FormattedMessage
                                                                id="UserProfile_page.Address"
                                                                defaultMessage="Address"
                                                            />
                                                        </label>
                                                        <input
                                                            name="Address"
                                                            type="text"
                                                            className="form-control AddAddressModal"
                                                            id="inputAddress"
                                                            placeholder={address1}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.errors.Address &&
                                                            formik.touched.Address && (
                                                                <p style={{ color: 'red' }}>
                                                                    {formik.errors.Address}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputState">
                                                            <FormattedMessage
                                                                id="UserProfile_page.States"
                                                                defaultMessage="States"
                                                            />
                                                        </label>
                                                        <select
                                                            name="states"
                                                            id="inputState"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                handelChangeState(e.target.value);
                                                            }}
                                                        >
                                                            <option
                                                                value={true}
                                                                disabled={true}
                                                                selected={true}
                                                            >
                                                                Select State
                                                            </option>
                                                            {statesData?.map((d) => {
                                                                return (
                                                                    <option
                                                                        value={d.name}
                                                                        key={d.id}
                                                                    >
                                                                        {d.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        {formik.errors.states &&
                                                            formik.touched.states && (
                                                                <p style={{ color: 'red' }}>
                                                                    {formik.errors.states}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputCity">
                                                            <FormattedMessage
                                                                id="UserProfile_page.City"
                                                                defaultMessage="City"
                                                            />
                                                        </label>
                                                        <select
                                                            name="City"
                                                            className="form-control AddAddressModal"
                                                            id="inputCity"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                handelChangeArea(e.target.value);
                                                            }}
                                                            value={formik.values.City}
                                                        >
                                                            <option
                                                                value={true}
                                                                disabled={true}
                                                                selected={true}
                                                            >
                                                                Select City
                                                            </option>
                                                            {cityData?.map((d) => {
                                                                return (
                                                                    <option value={d.id} key={d.id}>
                                                                        {d.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        {formik.errors.City &&
                                                            formik.touched.City && (
                                                                <p style={{ color: 'red' }}>
                                                                    {formik.errors.City}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputAddress2">
                                                            <FormattedMessage
                                                                id="UserProfile_page.Landmark"
                                                                defaultMessage="Landmark"
                                                            />
                                                        </label>
                                                        <input
                                                            name="Landmark"
                                                            type="text"
                                                            className="form-control AddAddressModal"
                                                            id="inputAddress2"
                                                            placeholder="Enter your near place"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.Landmark}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.errors.Landmark &&
                                                            formik.touched.Landmark && (
                                                                <p style={{ color: 'red' }}>
                                                                    {formik.errors.Landmark}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputAddress2">
                                                                <FormattedMessage
                                                                    id="UserProfile_page.Area"
                                                                    defaultMessage="Area"
                                                                />
                                                            </label>
                                                            <select
                                                                name="Area"
                                                                className="form-control AddAddressModal"
                                                                id="inputCity"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.Area}
                                                            >
                                                                <option
                                                                    value={true}
                                                                    disabled={true}
                                                                    selected={true}
                                                                >
                                                                    Select Area
                                                                </option>
                                                                {areaData?.map((d) => {
                                                                    return (
                                                                        <option
                                                                            value={d.name}
                                                                            key={d.id}
                                                                        >
                                                                            {d.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                            {formik.errors.Area &&
                                                                formik.touched.Area && (
                                                                    <p style={{ color: 'red' }}>
                                                                        {formik.errors.Area}
                                                                    </p>
                                                                )}
                                                        </div>

                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputZip">
                                                                <FormattedMessage
                                                                    id="UserProfile_page.Zip"
                                                                    defaultMessage="Zip"
                                                                />
                                                            </label>
                                                            <input
                                                                name="Zip"
                                                                type="text"
                                                                className="form-control AddAddressModal"
                                                                id="inputZip"
                                                                placeholder={zip}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.Zip}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.errors.Zip &&
                                                                formik.touched.Zip && (
                                                                    <p style={{ color: 'red' }}>
                                                                        {formik.errors.Zip}
                                                                    </p>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputState">
                                                                <FormattedMessage
                                                                    id="UserProfile_page.Address Type"
                                                                    defaultMessage="Address Type"
                                                                />
                                                            </label>
                                                            <select
                                                                name="AddressType"
                                                                id="inputState"
                                                                className="form-control"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.AddressType}
                                                            >
                                                                <option value="1">
                                                                    <FormattedMessage
                                                                        id="UserProfile_page.Home"
                                                                        defaultMessage="Home"
                                                                    />
                                                                </option>
                                                                <option value="2">
                                                                    <FormattedMessage
                                                                        id="UserProfile_page.Work"
                                                                        defaultMessage="Work"
                                                                    />
                                                                </option>
                                                                <option value="3">
                                                                    <FormattedMessage
                                                                        id="UserProfile_page.Other"
                                                                        defaultMessage="Other"
                                                                    />
                                                                </option>
                                                            </select>
                                                            {formik.errors.AddressType &&
                                                                formik.touched.AddressType && (
                                                                    <p style={{ color: 'red' }}>
                                                                        {formik.errors.AddressType}
                                                                    </p>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <button
                                                        className="btn btn-danger AddAddressModal mr-3"
                                                        onClick={() => setMapShow(true)}
                                                    >
                                                        Previous
                                                    </button>

                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger AddAddressModal"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <FormattedMessage
                                                            id="UserProfile_page.Add Address"
                                                            defaultMessage="Add Address"
                                                        />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer ModalHomeFooterProfile" />{' '}
                                </>
                            )}
                        </div>
                    </Modal>
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-lg-12">
                    <div className="card RespCheckoutCard">
                        <img
                            src={cancelPolicy}
                            className="card-img RespCancelPolicyImg"
                            alt="cancellation policy"
                        />
                        <div className="card-img-overlay CheckoutCancelPolicyBanner">
                            <h5 className="card-title CancelBannerFont">
                                <FormattedMessage
                                    id="Cart_page.Cancel1"
                                    defaultMessage="Review your order and address details to avoid cancellations"
                                />
                            </h5>
                            <p className="card-text">
                                <span className="CancelBannerFont">
                                    <FormattedMessage id="Cart_page.Note" defaultMessage="Note" /> :
                                </span>{' '}
                                <FormattedMessage
                                    id="Cart_page.Cancel2"
                                    defaultMessage="If you cancel within 60 seconds of placing your order, a 100% refund
                                    will be issued. No refund for cancellations made after 60 seconds."
                                />
                            </p>
                            <p className="card-text CancelBannerFont">
                                <FormattedMessage
                                    id="Cart_page.Cancel3"
                                    defaultMessage="Avoid cancellation as it leads to food wastage."
                                />
                            </p>
                            <a href="/" className="ReadCancellationPolicy">
                                <FormattedMessage
                                    id="Cart_page.Cancel4"
                                    defaultMessage="Read cancellation policy"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default injectIntl(CartAddress);
