import { setItem } from '../../../utils/utils';
import { postApi } from '../../api';
import {
    CITY_DATA_REQUEST,
    CITY_DATA_SUCCESS,
    CITY_DATA_FAILURE,
    CITY_DATA_CLEAR,
} from '../../types/types';

export const cityDataRequest = () => ({
    type: CITY_DATA_REQUEST,
});
export const cityDataSuccess = (payload) => ({
    type: CITY_DATA_SUCCESS,
    payload,
});
export const cityDataFailure = (error) => ({
    type: CITY_DATA_FAILURE,
    error,
});
export const clearCityData = () => ({
    type: CITY_DATA_CLEAR,
});
export const fetchCityData = (data) => async (dispatch) => {
    dispatch(cityDataRequest());
    return postApi(`get-cities`, data)
        .then((res) => {
            setItem('cityData', JSON.stringify(res.data));
            dispatch(cityDataSuccess(res.data));
            return res ?? res?.data ?? res?.data?.responseData ?? null;
        })
        .catch((e) => {
            dispatch(cityDataFailure(e));
        });
};
