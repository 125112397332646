import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import { fetchCreateReferralCode } from '../../../redux/actions/referralCode/createReferralCode';
import { tastesOnWayUrl } from '../../../utils/utils';
import { SUCCESS_TOASTIFY_TYPE } from '../../../utils/enum';
import Toastify from '../../common/Toastify';
import { FormattedMessage } from 'react-intl';
const RefrralCode = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [copied, setCopied] = useState(false);
    const { refrralCode } = useSelector((state) => ({
        refrralCode: state?.referralCode?.payload?.data,
    }));
    const copyUrlButton = () => {
        setCopied(true);
        Toastify(
            <FormattedMessage id="UserProfile_page.Copied" defaultMessage="Copied" />,
            SUCCESS_TOASTIFY_TYPE,
        );
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };
    const shareCodeText = `Please use this code to get a discount.\ncode:${refrralCode?.code}\nClick here:-`;
    const handleShow = () => {
        dispatch(fetchCreateReferralCode());
    };
    return (
        <>
            {refrralCode === 'null' ? (
                <div className="sd ">
                    <button className="btn btn-danger" onClick={handleShow}>
                        <FormattedMessage
                            id="UserProfile_page.Generate Referral code"
                            defaultMessage="Generate Referral code"
                        />
                    </button>
                </div>
            ) : (
                <div className="sd d-flex justify-content-between">
                    <div>
                        <FormattedMessage
                            id="UserProfile_page.Your Referral Code"
                            defaultMessage="Your Referral Code"
                        />{' '}
                        : <b>{`${refrralCode?.code}`}</b>
                    </div>
                    <button className="border-0 btn btn-danger" onClick={() => setShow(true)}>
                        <span>
                            {' '}
                            <FormattedMessage
                                id="UserProfile_page.Share Referral Code"
                                defaultMessage="Share Referral Code"
                            />
                        </span>
                    </button>
                </div>
            )}
            <Modal show={show} onHide={() => setShow(false)}>
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="modal-title" id="exampleModalLabel">
                            <FormattedMessage
                                id="UserProfile_page.Share Referral Code"
                                defaultMessage="Share Referral Code"
                            />
                        </span>
                        <button
                            href="/"
                            type="button"
                            className="close"
                            onClick={() => setShow(false)}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div
                            className="social-icons"
                            style={{ display: 'flex', justifyContent: 'space-around' }}
                        >
                            <TwitterShareButton
                                url={`https:${tastesOnWayUrl}`}
                                title={shareCodeText}
                            >
                                <TwitterIcon size={32} round={true} />
                                <span>
                                    <FormattedMessage
                                        id="UserProfile_page.Twitter"
                                        defaultMessage="Twitter"
                                    />
                                </span>
                            </TwitterShareButton>
                            <FacebookMessengerShareButton
                                url={`https:${tastesOnWayUrl}`}
                                title={shareCodeText}
                            >
                                <FacebookMessengerIcon size={32} round={true} />
                                <span>
                                    <FormattedMessage
                                        id="UserProfile_page.Messenger"
                                        defaultMessage="Messenger"
                                    />
                                </span>
                            </FacebookMessengerShareButton>
                            <WhatsappShareButton
                                url={`https:${tastesOnWayUrl}`}
                                title={shareCodeText}
                            >
                                <WhatsappIcon size={32} round={true} />
                                <span>
                                    <FormattedMessage
                                        id="UserProfile_page.WhatsApp"
                                        defaultMessage="WhatsApp"
                                    />
                                </span>
                            </WhatsappShareButton>
                            <TelegramShareButton
                                url={`https:${tastesOnWayUrl}`}
                                title={shareCodeText}
                            >
                                <TelegramIcon size={32} round={true} />
                                <span>
                                    <FormattedMessage
                                        id="UserProfile_page.Telegram"
                                        defaultMessage="Telegram"
                                    />
                                </span>
                            </TelegramShareButton>
                            <CopyToClipboard
                                text={`${shareCodeText}https:${tastesOnWayUrl}`}
                                onCopy={() => copyUrlButton()}
                            >
                                <div
                                    style={{ cursor: 'pointer', textAlign: 'center', marginTop: 5 }}
                                >
                                    <span>
                                        {copied ? (
                                            <FormattedMessage
                                                id="UserProfile_page.Copied"
                                                defaultMessage="Copied"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="UserProfile_page.Copy URL"
                                                defaultMessage="Copy URL"
                                            />
                                        )}
                                    </span>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RefrralCode;
