import { GET_IN_TOUCH_REQUES, GET_IN_TOUCH_SUCCESS, GET_IN_TOUCH_FAILURE } from '../../types/types';

const initialState = {
    loading: false,
    payload: [],
    error: '',
};

const getInTouchReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_IN_TOUCH_REQUES:
            return {
                loading: true,
                payload: [],
                error: '',
            };
        case GET_IN_TOUCH_SUCCESS:
            return {
                loading: false,
                payload: action.payload,
                error: '',
            };
        case GET_IN_TOUCH_FAILURE:
            return {
                loading: false,
                payload: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export default getInTouchReducer;
