import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import {
    fetchCreateOrderDetails,
    fetchDeliveryPersonLocation,
} from '../../services/orederDetails/OrderDetails';
import dileveryBoy from '../../assets/mapicons/deliveryboy.png';
import user from '../../assets/mapicons/home-delivery.png';
import restaurant from '../../assets/mapicons/kitchen-owner.png';

const TrackOrder = () => {
    const directionService = useRef(null);
    const dispatch = useDispatch();
    const [kitchenOwnerLocation, setKitchenOwnerLocation] = useState();
    const [endUserLocation, setEndUserLocation] = useState();
    const [dileveryPersonAssign, setDileveryPersonAssign] = useState();
    const [dileveryPersonId, setDileveryPersonId] = useState();
    const [mapCenter, setMapCenter] = useState();
    console.log('🚀 ~ file: TrackOrder.jsx:21 ~ TrackOrder ~ mapCenter:', mapCenter);
    const [deliveryBoyLocation, setDeliveryBoyLocation] = useState({
        lat: 0,
        lng: 0,
    });
    const [restaurantLocation, setRestaurantLocation] = useState({
        lat: 0,
        lng: 0,
    });
    const [userLocation, setUserLocation] = useState({
        lat: 0,
        lng: 0,
    });
    const [route, setRoute] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const res = await dispatch(fetchCreateOrderDetails({ order_id: '222' }));
            setEndUserLocation({
                lat: Number(res.data.data.user_address.latitude),
                lng: Number(res.data.data.user_address.longitude),
            });
            setKitchenOwnerLocation({
                lat: Number(res.data.data.business_owner_address.latitude),
                lng: Number(res.data.data.business_owner_address.longitude),
            });

            setDileveryPersonAssign(res.data.data.delivery_person);
            setDileveryPersonId(res.data.data.delivery_person.id);
        }
        fetchData();
        if (dileveryPersonId) {
            const data = {
                delivery_person_id: dileveryPersonId,
            };
            dispatch(fetchDeliveryPersonLocation(data)).then((res) => {
                console.log(
                    '🚀 ~ file: TrackOrder.jsx:52 ~ dispatch ~ res:',
                    res.data.data.getLocationData,
                );
                setDeliveryBoyLocation(res.data.data.getLocationData);
                setMapCenter(res.data.data.getLocationData);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dileveryPersonId]);

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
    };

    const toRadians = (angle) => (angle * Math.PI) / 180;

    const calculateDistance = (location1, location2) => {
        const R = 6371; // Earth's radius in km
        const dLat = toRadians(location2.lat - location1.lat);
        const dLon = toRadians(location2.lng - location1.lng);
        const lat1 = toRadians(location1.lat);
        const lat2 = toRadians(location2.lat);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in km

        return distance;
    };
    useEffect(() => {
        if (kitchenOwnerLocation && endUserLocation) {
            handleApiLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kitchenOwnerLocation, endUserLocation]);

    const handleApiLoad = () => {
        const deliveryBoyLocationData = {
            lat: deliveryBoyLocation?.lat,
            lng: deliveryBoyLocation?.long,
        };
        const restaurantLocationData = {
            lat: kitchenOwnerLocation?.lat,
            lng: kitchenOwnerLocation?.lng,
        };

        const userLocationData = {
            lat: endUserLocation?.lat,
            lng: endUserLocation?.lng,
        };

        const distance = calculateDistance(
            deliveryBoyLocationData,
            restaurantLocationData,
            userLocationData,
        );
        console.log(`Distance: ${Math.round(distance)} km`);
        const speed = 30; // km/hour (assumed speed)
        const time = distance / speed; // Time in hours
        const timeInMin = time * 60;
        const times = Math.round(timeInMin);
        console.log(`Time: ${times} min`);

        setDeliveryBoyLocation(deliveryBoyLocationData);
        setRestaurantLocation(restaurantLocationData);
        setUserLocation(userLocationData);
        directionService.current = new window.google.maps.DirectionsService();

        directionService.current.route(
            {
                origin: restaurantLocationData,
                destination: userLocationData,
                waypoints: [{ location: userLocationData }],
                travelMode: 'DRIVING',
            },
            (result, status) => {
                if (status === 'OK') {
                    const points = result.routes[0].overview_path.map((point) => ({
                        lat: point.lat(),
                        lng: point.lng(),
                    }));
                    setRoute(points);
                } else {
                    console.error(`Directions service failed due to ${status}`);
                }
            },
        );
    };

    const defaultCenter = mapCenter
        ? { lat: mapCenter.lat, lng: mapCenter.long }
        : { lat: kitchenOwnerLocation?.lat, lng: kitchenOwnerLocation?.lng };

    const defaultZoom = 15;

    return (
        <div className="col-lg-6">
            <div className="BadgeMainDiv">
                <button
                    className="badge TrackOrder"
                    data-toggle="modal"
                    data-target="#exampleModaltrack"
                >
                    Track Order <img src="images/trackorder.png" alt="track-order" />
                </button>
                <div
                    className="modal fade"
                    id="exampleModaltrack"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content ContentLeft">
                            <div className="modal-header">
                                <h4 className="modal-title" id="exampleModalLabel">
                                    Track Your Order
                                </h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="text-center">Ordered from</p>
                                <h4 className="text-center">Jiger&apos;s Kitchen</h4>
                                <hr />
                                <h4 className="text-center">Your Ordrer is on the way</h4>
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    center={defaultCenter}
                                    zoom={defaultZoom}
                                    onLoad={handleApiLoad}
                                    onClick={(event) => {
                                        console.log(
                                            'Map clicked:',
                                            event.latLng.lat(),
                                            event.latLng.lng(),
                                        );
                                    }}
                                >
                                    {dileveryPersonAssign !== null && deliveryBoyLocation && (
                                        <Marker
                                            position={deliveryBoyLocation}
                                            icon={{
                                                url: dileveryBoy,
                                                scaledSize: new window.google.maps.Size(40, 70),
                                            }}
                                        />
                                    )}
                                    {restaurantLocation && (
                                        <Marker
                                            position={restaurantLocation}
                                            icon={{
                                                url: restaurant,

                                                scaledSize: new window.google.maps.Size(40, 40),
                                            }}
                                        />
                                    )}
                                    {userLocation && (
                                        <Marker
                                            position={userLocation}
                                            icon={{
                                                url: user,
                                                scaledSize: new window.google.maps.Size(40, 40),
                                            }}
                                        />
                                    )}
                                    {route && (
                                        <Polyline
                                            path={route}
                                            options={{
                                                strokeColor: '#4e4e95',
                                                strokeWeight: 5,
                                                strokeDasharray: [5, 5],
                                            }}
                                        />
                                    )}
                                </GoogleMap>

                                {/* <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <div className="stepper d-flex flex-column">
                                                            <div className="d-flex mb-1">
                                                                <div className="d-flex flex-column pr-4 align-items-center">
                                                                    <div className="rounded-circle py-2 px-2 bg-danger text-white mb-1" />
                                                                    <div className="lineVert" />
                                                                </div>
                                                                <div>
                                                                    <div className="text-dark TextContent">
                                                                        Settalite Road, Ahmedabad
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mb-1">
                                                                <div className="d-flex flex-column pr-4 align-items-center">
                                                                    <div className="rounded-circle py-2 px-2 bg-danger text-white mb-1" />
                                                                </div>
                                                                <div>
                                                                    <div className="text-dark TextContent">
                                                                        Gargin Plaza 5/3, Ahmedabad
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="TextContent TextEnd">
                                                            <img
                                                                src="images/rupeeiconblack.png"
                                                                className="RupeeBlackWidth"
                                                                alt="rupee"
                                                            />
                                                            320
                                                        </div>
                                                        <div className="TextContent TextEnd MargintopDist">
                                                            3km
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 

                                 <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card mt-4">
                                            <div className="card-header cardheadwhite">
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <img
                                                            src="images/johndoerider.png"
                                                            className="WidthProfImgRider"
                                                            alt="rider"
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="HeadProfBoxRider">
                                                            John Doe | Rider
                                                        </div>
                                                        <div className="NormalTextProfRide mt-3">
                                                            <img
                                                                src="images/phone-call.png"
                                                                className="WidthIconCall"
                                                                alt="phone"
                                                            />
                                                            9685743625
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="TextEnd">
                                                            <span className="TextColor TextContent">
                                                                4.5
                                                            </span>
                                                            <img
                                                                src="images/star.png"
                                                                className="RatingImg"
                                                                alt="star"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="modal-footer ModalHomeFooterProfile" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackOrder;
