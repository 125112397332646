/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import LandingSearchBar from '../landingSearchBar/LandingSearchBar';
import LandingWidget1 from '../landingWidget1/LandingWidget1';
import LandingCarousel1 from '../landingCarousel1/LandingCarousel1';
import LandingCarousel2 from '../landingCarousel2/LandingCarousel2';
import LandingFAQ from '../landingFAQ/LandingFAQ';
import { fetchKitchenOwnerList } from '../../../redux/actions/kitchenOwner/KitchenOwnerList';
import Geocode from 'react-geocode';
import { fetchTopFiveRestaurantsList } from '../../../redux/actions/kitchenOwner/TopFiveRestaurantsList';
import { getItem, removeItem } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import '../../../assets/styles/media.css';
import '../../landingPage/landingPage/LandingPage.css';
import Toastify from '../../common/Toastify';

const LandingPage = () => {
    const dispatch = useDispatch();
    const { kitchenOwnerList } = useSelector((state) => ({
        kitchenOwnerList: state?.kitchenOwnerList?.error?.response?.status,
    }));

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = getItem('lat');
                    const long = getItem('long');
                    Geocode.setApiKey('AIzaSyDcjtGb2jSVKXsUjxVAcJx6hboHbUe6fqI');
                    Geocode.setLanguage('en');
                    Geocode.setRegion('IN');
                    Geocode.setLocationType('ROOFTOP');
                    Geocode.enableDebug();
                    const latitude = lat ? lat : position.coords.latitude;
                    const longitude = long ? long : position.coords.longitude;
                    Geocode.fromLatLng(latitude, longitude).then(
                        (response) => {
                            const areaName =
                                response?.results?.[0]?.address_components?.[1]?.long_name;
                            const cityname =
                                response?.results?.[0]?.address_components?.[2]?.short_name;

                            const apiData = {
                                lat: lat,
                                long: long,
                                city_name: cityname,
                                radius: '5',
                                search_by: '',
                                page_size: '3',
                                area_name: areaName,
                            };

                            dispatch(fetchKitchenOwnerList(apiData, 1));
                            dispatch(fetchTopFiveRestaurantsList(apiData));
                        },
                        (error) => {
                            return error;
                        },
                    );
                },
                (error) => {
                    return error;
                },
            );
        } else {
            Toastify('Geolocation is not supported by this browser.', 'error');
        }
        if (kitchenOwnerList == 401) {
            removeItem('token');
        }
    }, []);

    return (
        <>
            <div className="LandingPageBgRestaurents">
                <LandingSearchBar />
            </div>
            <div className="container TopMargin">
                <div className=" TopMargin">
                    <LandingCarousel1 />
                </div>
                <LandingCarousel2 />
                <LandingWidget1 />
            </div>
            <LandingFAQ />
        </>
    );
};

export default LandingPage;
