import React, { useState, useEffect } from 'react';
import { fetchCancelOrder } from '../../redux/actions/order/cancelOrder';
import { FormattedMessage } from 'react-intl';
import { getItem, removeItem, setItem } from '../../utils/utils';
import { fetchCreateOrderDetails } from '../../redux/actions/order/orderDetails';
import { useDispatch, useSelector } from 'react-redux';
import rupee from '../../assets/images/rupee.png';
import '../../assets/styles/media.css';
import './OrderDetails.css';
import { createOrderClean } from '../../redux/actions/order/createOrder';
import trackorder from '../../assets/images/trackorder.png';
import { Modal } from 'react-bootstrap';
import TrackOrder from '../UserProfile/TrackOrder';
import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';
import Toastify from '../common/Toastify';
import { INFO_TOASTIFY_TYPE } from '../../utils/enum';

const OrderDetails = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [cancelShow, setCancelShow] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();
    const handelCloseChange = () => {
        setCancelShow(false);
    };
    const { orderDetails } = useSelector((state) => ({
        orderDetails: state?.orderDetails?.payload?.data,
    }));
    console.log(
        '🚀 ~ file: OrderDetails.jsx:29 ~ const{orderDetails}=useSelector ~ orderDetails:',
        orderDetails,
    );
    const [orderStatus, setOrderStatus] = useState(orderDetails?.order_status);

    const [timeLeft, setTimeLeft] = useState(60);

    const date = orderDetails?.date_for_incoming_order;
    const orderId = getItem('orderID');

    useEffect(() => {
        const apiData = {
            order_id: orderId,
        };
        dispatch(fetchCreateOrderDetails(apiData));

        return () => {
            dispatch(createOrderClean());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const storedTime = getItem('orderTime');
        const storedTimeLeft = storedTime ? Math.floor((storedTime - Date.now()) / 1000) : null;

        if (storedTimeLeft !== null && storedTimeLeft <= 60) {
            setTimeLeft(storedTimeLeft);
            const timer = setTimeout(() => {
                setIsVisible(false);
                navigate('/profile');
            }, storedTimeLeft * 1000);

            const interval = setInterval(() => {
                setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
            }, 1000);

            return () => {
                clearTimeout(timer);
                clearInterval(interval);
            };
        } else {
            removeItem('orderTime');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setItem('orderTime', Date.now() + timeLeft * 1000);
    }, [timeLeft]);

    useEffect(() => {
        const pusher = new Pusher('9edf8c2026f57e4d7421', {
            cluster: 'ap2',
        });
        const channel1 = pusher.subscribe('taste-on-way-demo');
        channel1.bind(orderId, function (data) {
            setOrderStatus(data.order_status);
        });

        return () => {
            pusher.unsubscribe('taste-on-way-demo');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cancelOrder = () => {
        const orderId = getItem('orderID');
        const apiData = {
            order_id: orderId,
            order_status: '6',
        };
        dispatch(fetchCancelOrder(apiData));
        removeItem('orderTime');
        setIsVisible(false);
        navigate('/profile');
        Toastify(
            'You will get refund within 7 working days for your cancel order',
            INFO_TOASTIFY_TYPE,
        );
    };
    return (
        <div>
            <>
                <div className="container mb-5">
                    <div className="row TopMargin">
                        <div className="col-lg-12">
                            <h4 className="text-center TextColor">
                                <FormattedMessage
                                    id="Order_details.Thanks for choosing Tastes on way! Here are your order details:"
                                    defaultMessage="Thanks for choosing Tastes on way! Here are your order details:"
                                />
                            </h4>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <h4 className="text-center">
                                <FormattedMessage
                                    id="Order_details.Order No :"
                                    defaultMessage="Order No :"
                                />{' '}
                                <span className="RedColorFont">{`#${orderDetails?.order_no}`}</span>
                            </h4>
                        </div>
                    </div>
                    {orderDetails?.order_status == 'Cancelled' ? (
                        <div className="d-flex justify-content-center alert alert-danger">
                            <FormattedMessage
                                id="Order_details.You will get refund within 7 working days for your cancel order"
                                defaultMessage="You will get refund within 7 working days for your cancel order"
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="row TopMargin">
                        <div className="col-lg-12">
                            <div className="DivDeliveryAddress">
                                <div className="row">
                                    <div className="col">
                                        <div className="DeliciousDosaFont">
                                            <FormattedMessage
                                                id="Order_details.Order placed at :"
                                                defaultMessage="Order placed at :"
                                            />{' '}
                                            {` ${date?.split('-').reverse().join('/')}, ${
                                                orderDetails?.time_for_incoming_order
                                            }`}
                                        </div>
                                        <div className="OrderFromText">
                                            <FormattedMessage
                                                id="Order_details.Ordered From:"
                                                defaultMessage="Ordered From:"
                                            />
                                        </div>
                                        <div className="PlacesText">
                                            {orderDetails?.business_owner_address?.office_name}
                                        </div>
                                        <div className="TextColor">
                                            {orderDetails?.business_owner_address?.area}
                                        </div>
                                    </div>
                                    {orderDetails?.order_status === 'Cancelled' ? (
                                        <span style={{ color: 'red' }}>Cancelled</span>
                                    ) : (
                                        <div>
                                            <button
                                                className="badge TrackOrder border-0"
                                                onClick={() =>
                                                    navigate({
                                                        pathname: '/profile',
                                                        search: `order_id=${orderDetails?.id}`,
                                                    })
                                                }
                                            >
                                                <FormattedMessage
                                                    id="UserProfile_page.Track Order"
                                                    defaultMessage="Track Order"
                                                />
                                                <img src={trackorder} alt="trackorder" />
                                            </button>
                                        </div>
                                    )}
                                    <Modal
                                        show={show}
                                        onHide={() => setShow(!show)}
                                        animation={false}
                                        size="lg"
                                    >
                                        <div className="modal-header">
                                            <h6 className="modal-title" id="exampleModalLabel">
                                                <FormattedMessage
                                                    id="UserProfile_page.Track Order"
                                                    defaultMessage="Track Order"
                                                />{' '}
                                                {orderDetails?.order_status &&
                                                    ` : ${orderDetails?.order_status.toUpperCase()}`}
                                            </h6>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => setShow(false)}
                                            >
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body d-flex justify-content-center">
                                            <TrackOrder data={orderDetails} />
                                        </div>
                                    </Modal>
                                    <div className="col">
                                        <div className="DeliciousDosaFont TextRightBox">
                                            <FormattedMessage
                                                id="Order_details.Order Status :"
                                                defaultMessage="Order Status :"
                                            />{' '}
                                            {orderStatus || orderDetails?.order_status}
                                        </div>

                                        <div className="OrderFromText TextRightBox">
                                            <FormattedMessage
                                                id="Order_details.Delivered To:"
                                                defaultMessage="Delivered To:"
                                            />
                                        </div>
                                        <div className="PlacesText TextRightBox">
                                            {orderDetails?.user?.name}
                                        </div>
                                        <div className="TextColor TextRightBox">
                                            {orderDetails?.user_address?.area}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <div className="OrderFromText">
                                        <FormattedMessage
                                            id="Order_details.Item Name:"
                                            defaultMessage="Item Name:"
                                        />
                                    </div>
                                    {orderDetails?.order_detail.map((i) => {
                                        return i.menu_items.map((d) => {
                                            return (
                                                <div key={d.id} className="PlacesText">
                                                    {i.quantity} <span>x</span> {d.name}
                                                </div>
                                            );
                                        });
                                    })}
                                </div>
                                <div className="col">
                                    <div className="OrderFromText TextRightBox">
                                        <FormattedMessage
                                            id="Order_details.Price"
                                            defaultMessage="Price"
                                        />
                                    </div>
                                    {orderDetails?.order_detail.map((i) => {
                                        return (
                                            <div key={i.id} className="PlacesText TextRightBox">
                                                <img src={rupee} className="RupeeImg" alt="" />
                                                {i.item_price}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <hr className="mt-5" />
                            <div className="row mt-4">
                                <div className="col">
                                    <div className="PlacesText">
                                        <FormattedMessage
                                            id="Order_details.Delivery Fee:"
                                            defaultMessage="Delivery Fee:"
                                        />{' '}
                                        <span className="PlacesText TextRightBox TextFloatright">
                                            <img src={rupee} className="RupeeImg" alt="" />
                                            {orderDetails?.delivery_charge}
                                        </span>
                                    </div>
                                    <div className="PlacesText">
                                        <FormattedMessage
                                            id="Order_details.Total :"
                                            defaultMessage="Total :"
                                        />{' '}
                                        <span className="PlacesText TextRightBox TextFloatright">
                                            <img src={rupee} className="RupeeImg" alt="" />
                                            {orderDetails?.order_total}
                                        </span>
                                    </div>
                                    {orderDetails?.coupon_amount ? (
                                        <div className="PlacesText">
                                            <FormattedMessage
                                                id="Order_details.Discount :"
                                                defaultMessage="Discount :"
                                            />

                                            <span className="PlacesText TextRightBox TextFloatright">
                                                -
                                                <img src={rupee} className="RupeeImg" alt="" />
                                                {orderDetails?.coupon_amount}
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <div className="PlacesText">
                                        <FormattedMessage
                                            id="Order_details.Tax Collected :"
                                            defaultMessage="Tax Collected :"
                                        />

                                        <span className="PlacesText TextRightBox TextFloatright">
                                            <img src={rupee} className="RupeeImg" alt="" />
                                            {orderDetails?.tax_amount}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4" />
                            <div className="row mt-4">
                                <div className="col">
                                    <div className="PlacesText">
                                        <FormattedMessage
                                            id="Order_details.Grand Total"
                                            defaultMessage="Grand Total"
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="PlacesText TextRightBox">
                                        <img src={rupee} className="RupeeImg" alt="" />
                                        {orderDetails?.order_final_total}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            <Modal show={cancelShow} onHide={handelCloseChange} backdrop="static">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage
                            id="Order_details.CancelOrder"
                            defaultMessage="CancelOrder"
                        />
                    </Modal.Title>
                    <button type="button" className="close" onClick={handelCloseChange}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage
                        id="Order_details.Are you sure you want to cancel this order?"
                        defaultMessage="Are you sure you want to cancel this order?"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn mr-2 btn-outline-danger btnradius"
                        onClick={handelCloseChange}
                    >
                        <FormattedMessage id="UserProfile_page.NO" defaultMessage="NO" />
                    </button>
                    <button
                        onClick={() => {
                            cancelOrder();
                            handelCloseChange();
                            setIsVisible(false);
                        }}
                        className="btn btn-danger btnradius"
                    >
                        <FormattedMessage id="UserProfile_page.YES" defaultMessage="YES" />
                    </button>
                </Modal.Footer>
            </Modal>

            {isVisible ? (
                <div className="d-flex justify-content-center flex-column">
                    <div className=" d-flex justify-content-center direction-row">
                        <div className="mb-5">
                            You Can Cancel Order in ({timeLeft} seconds){' '}
                            <button className="cancel-order-link" onClick={cancelOrder}>
                                Cancel Order
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default OrderDetails;
