import React, { useState, useEffect } from 'react';
import { fetchKitchenOwnerMenuItemList } from '../../../redux/actions/kitchenOwner/KitchenOwnerMenuItemList';
import { fetchKitchenOwnerMenuList } from '../../../redux/actions/kitchenOwner/KitchenOwnerMenuList';
import { addToCart, decrementQuantity, incrementQuantity } from '../../../redux/actions/cart/cart';
import { getItem, setItem, transferDataAddToCart } from '../../../utils/utils';
import { fetchAddToCart } from '../../../redux/actions/cart/AddToCart';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import previousSliderImg from '../../../assets/images/previous-slider.png';
import nextSliderImg from '../../../assets/images/next-slider.png';
import nextImg from '../../../assets/images/next.png';
import rupee from '../../../assets/images/rupee.png';
import customize from '../../../assets/images/customize.png';
import './RestaurantMenuItem.css';
import { INFO_TOASTIFY_TYPE } from '../../../utils/enum';
import Toastify from '../../common/Toastify';
const RestaurantMenuItem = () => {
    const { MenuId, KitchenOwnerId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(4);
    const [active, setActive] = useState(null);
    const [modal, setModal] = useState([]);
    const [count, setCount] = useState(1);
    const [itemChecked, setItemChecked] = useState([]);
    const lat = getItem('lat');
    const long = getItem('long');

    const menuItems = JSON.parse(getItem('cartData'));
    const [show, setShow] = useState(false);

    const { kitchenOwnerMenuList, kitchenOwnerMenuItemList, cartItems, cartItemId } = useSelector(
        (state) => ({
            kitchenOwnerMenuList: state?.kitchenOwnerMenuList?.payload?.data,
            kitchenOwnerMenuItemList: state?.kitchenOwnerMenuItemList?.payload?.data?.[0],
            cartItems: state?.cartItems?.payload,
            cartItemId: state?.cartItems?.id,
        }),
    );
    useEffect(() => {
        setActive(MenuId);
        const apiData = {
            business_owner_id: KitchenOwnerId,
            menu_id: MenuId,
        };
        dispatch(fetchKitchenOwnerMenuItemList(apiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const apiData = {
            business_owner_id: KitchenOwnerId,
            page_size: '4',
        };
        dispatch(fetchKitchenOwnerMenuList(apiData, count));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    const nextOnClick = () => {
        setCount((prv) => prv + 1);
        setVisible((prv) => prv + 4);
    };

    const prviousOnClick = () => {
        setCount((prv) => prv - 1);
        setVisible((prv) => prv - 4);
    };

    const handleClose = () => {
        setShow(false);
        setItemChecked([]);
    };
    const menuItemClick = (id) => {
        const apiData = {
            business_owner_id: KitchenOwnerId,
            menu_id: id,
        };
        setActive(id);
        dispatch(fetchKitchenOwnerMenuItemList(apiData));
    };

    const handleShow = () => setShow(!show);

    const onClickItemAdd = (data) => {
        if (menuItems?.length > 0) {
            const quantityData = { ...data, quantity: 1 };
            const apiData = [...menuItems, quantityData];
            if (
                apiData[0].business_owner.kitchen_owner_cuisine[0].business_owner_id !=
                KitchenOwnerId
            ) {
                Toastify('Last Kitchen owner items removed', INFO_TOASTIFY_TYPE);
            }
            const newData = apiData.filter(
                (d) =>
                    d.business_owner.kitchen_owner_cuisine[0].business_owner_id == KitchenOwnerId,
            );
            setItem('cartData', JSON.stringify(newData));
            dispatch(addToCart(apiData, [...cartItemId, data.id]));
        } else {
            const quantityData = { ...data, quantity: 1 };
            const apiData = [...cartItems, quantityData];
            const newData = apiData.filter(
                (d) =>
                    d.business_owner.kitchen_owner_cuisine[0].business_owner_id == KitchenOwnerId,
            );
            setItem('cartData', JSON.stringify(newData));
            dispatch(addToCart(apiData, [...cartItemId, data.id]));
        }
    };

    const incrementQuantityOnClick = (id) => {
        if (menuItems?.length > 0) {
            const data = menuItems?.map((item) => {
                if (item.id === id) {
                    const quantity = item.quantity + 1;
                    return { ...item, quantity };
                }
                return item;
            });
            setItem('cartData', JSON.stringify(data));
            dispatch(incrementQuantity(data, cartItemId));
        } else {
            const data = cartItems?.map((item) => {
                if (item.id === id) {
                    const quantity = item.quantity + 1;
                    return { ...item, quantity };
                }
                return item;
            });
            setItem('cartData', JSON.stringify(data));
            dispatch(incrementQuantity(data, cartItemId));
        }
    };

    const decrementQuantityOnClick = (id) => {
        if (menuItems?.length > 0) {
            const data = menuItems?.map((item) => {
                if (item.id === id) {
                    const quantity = item.quantity - 1;
                    if (quantity === 0) {
                        const newId = cartItemId.indexOf(id);
                        if (newId > -1) {
                            cartItemId.splice(newId, 1);
                            dispatch(decrementQuantity(item, cartItemId));
                        }
                    }
                    return { ...item, quantity };
                }
                return item;
            });
            const cartData = data.filter((id) => id.quantity > 0);
            setItem('cartData', JSON.stringify(cartData));
            dispatch(decrementQuantity(cartData, cartItemId));
        } else {
            const data = cartItems?.map((item) => {
                if (item.id === id) {
                    const quantity = item.quantity - 1;
                    if (quantity === 0) {
                        const newId = cartItemId.indexOf(id);
                        if (newId > -1) {
                            cartItemId.splice(newId, 1);
                            dispatch(decrementQuantity(item, cartItemId));
                        }
                    }
                    return { ...item, quantity };
                }
                return item;
            });
            const cartData = data.filter((id) => id.quantity > 0);
            setItem('cartData', JSON.stringify(cartData));
            dispatch(decrementQuantity(cartData, cartItemId));
        }
    };

    const checkOutPage = () => {
        if (menuItems?.length > 0) {
            const menuItem = transferDataAddToCart(menuItems);
            const apiData = {
                lat: lat,
                long: long,
                redious: '5',
                menu_items: menuItem,
            };
            dispatch(fetchAddToCart(apiData));
            navigate('/cartDetails');
        } else {
            const menuItems = transferDataAddToCart(cartItems);
            const apiData = {
                lat: lat,
                long: long,
                redious: '5',
                menu_items: menuItems,
            };
            setItem('cartData', JSON.stringify(cartItems));
            dispatch(fetchAddToCart(apiData));
            navigate('/cartDetails');
        }
    };

    const handleChangeCheckBox = (e, ing, data) => {
        const { value, checked } = e.target;
        if (checked) {
            setItemChecked([...itemChecked, ing]);
        } else {
            if (itemChecked.length > 0) {
                const filterData = itemChecked.filter((id) => id.id != value);
                setItemChecked(filterData);
            } else {
                const fd = data.extraItem.filter((x) => Number(x.id) != Number(value));
                setItemChecked([...fd]);
            }
        }
    };

    const addItemClick = (id) => {
        if (menuItems?.length > 0) {
            const data = menuItems?.map((item) => {
                if (Number(item.id) === Number(id)) {
                    const itemAmount = itemChecked.map((d) => {
                        return Number(d.price);
                    });
                    const amount =
                        Number(item.amount) +
                        itemAmount.reduce((sum, acum) => Number(sum) + Number(acum), 0);
                    return { ...item, extraItem: itemChecked, finalAmount: amount };
                }
                return item;
            });
            setItemChecked([]);
            setItem('cartData', JSON.stringify(data));
            dispatch(addToCart(data, cartItemId));
            setShow(!show);
        } else {
            const data = cartItems?.map((item) => {
                if (item.id === id) {
                    const itemAmount = itemChecked.map((d) => {
                        return d.price;
                    });
                    const amount = item.amount + itemAmount.reduce((sum, acum) => sum + acum, 0);
                    return { ...item, extraItem: itemChecked, finalAmount: amount };
                }
                return item;
            });
            setItemChecked([]);
            setItem('cartData', JSON.stringify(data));
            dispatch(addToCart(data, cartItemId));
            setShow(!show);
        }
    };

    return (
        <div className="row TopMargin">
            <div className="col-lg-12">
                <div className="">
                    <h3>
                        <FormattedMessage
                            id="RestaurentDetails.Featured Items"
                            defaultMessage="Featured Items"
                        />
                    </h3>
                </div>
                <div className="DivClassControlsRestDetails">
                    <button
                        className={
                            visible <= 4 ? 'carousel-control-prev d-none' : 'carousel-control-prev'
                        }
                        type="button"
                        data-target="#carouselExampleInterval"
                        data-slide="prev"
                        onClick={prviousOnClick}
                    >
                        <img src={previousSliderImg} alt="" className="ImgCarouselWidth" />
                    </button>
                    <button
                        className={
                            visible >= kitchenOwnerMenuList?.total
                                ? 'carousel-control-next d-none'
                                : 'carousel-control-next '
                        }
                        type="button"
                        data-target="#carouselExampleInterval"
                        data-slide="next"
                        onClick={nextOnClick}
                    >
                        <img src={nextSliderImg} alt="" className="ImgCarouselWidth" />
                    </button>
                </div>

                <div className="row TopMargin">
                    <div className="col-lg-12">
                        <nav>
                            <div
                                className="nav nav-tabs nav-justified NavFontSizeTab"
                                id="nav-tab"
                                role="tablist"
                            >
                                {kitchenOwnerMenuList?.data?.map((data) => {
                                    return (
                                        <button
                                            className={
                                                active == data.id ? 'nav-link active' : 'nav-link'
                                            }
                                            id="nav-home-tab"
                                            data-toggle="tab"
                                            data-target="#nav-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-home"
                                            aria-selected="true"
                                            key={data.id}
                                            onClick={() => menuItemClick(data.id)}
                                        >
                                            {data.name}
                                        </button>
                                    );
                                })}
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                            >
                                {}
                                <div>
                                    {kitchenOwnerMenuItemList &&
                                        kitchenOwnerMenuItemList?.map((data) => {
                                            return (
                                                <div className="row TopMargin" key={data.id}>
                                                    <div className="col-lg-4">
                                                        <div
                                                            className="card CardCheckout"
                                                            style={{ width: '18rem' }}
                                                        >
                                                            <img
                                                                src={data.picture}
                                                                className="card-img-top"
                                                                alt="..."
                                                                style={{
                                                                    height: '15rem',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                            <div className="CardShadow">
                                                                <div className="CardBoxShadow">
                                                                    {menuItems ? (
                                                                        <div>
                                                                            {menuItems?.filter(
                                                                                (x) =>
                                                                                    x.id ===
                                                                                    data.id,
                                                                            ).length > 0 ? (
                                                                                menuItems
                                                                                    ?.filter(
                                                                                        (x) =>
                                                                                            x.id ===
                                                                                            data.id,
                                                                                    )
                                                                                    ?.map((iv) => {
                                                                                        {
                                                                                            return (
                                                                                                <div
                                                                                                    key={
                                                                                                        iv.id
                                                                                                    }
                                                                                                >
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btnCard"
                                                                                                        onClick={() =>
                                                                                                            decrementQuantityOnClick(
                                                                                                                data.id,
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        -
                                                                                                    </button>

                                                                                                    {menuItems.map(
                                                                                                        (
                                                                                                            v,
                                                                                                        ) => {
                                                                                                            if (
                                                                                                                v.id ===
                                                                                                                data.id
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    <span
                                                                                                                        key={
                                                                                                                            v.id
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            v.quantity
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                );
                                                                                                            }
                                                                                                        },
                                                                                                    )}

                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btnCard"
                                                                                                        onClick={() =>
                                                                                                            incrementQuantityOnClick(
                                                                                                                data.id,
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        +
                                                                                                    </button>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    })
                                                                            ) : (
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btnCard"
                                                                                    onClick={() =>
                                                                                        onClickItemAdd(
                                                                                            data,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FormattedMessage
                                                                                        id="RestaurentDetails.Add"
                                                                                        defaultMessage="Add"
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btnCard"
                                                                            onClick={() =>
                                                                                onClickItemAdd(data)
                                                                            }
                                                                        >
                                                                            <FormattedMessage
                                                                                id="RestaurentDetails.Add"
                                                                                defaultMessage="Add"
                                                                            />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <span className="badge badge-danger">
                                                            <FormattedMessage
                                                                id="RestaurentDetails.Bestseller"
                                                                defaultMessage="Bestseller"
                                                            />
                                                        </span>
                                                        <div className="mt-4 DeliciousDosaFont">
                                                            {data.name}
                                                        </div>
                                                        <div className="mt-2 DeliciousDosaFont">
                                                            <img
                                                                src={rupee}
                                                                alt=""
                                                                className="RupeeImg"
                                                            />
                                                            {data.amount}
                                                        </div>
                                                        {menuItems
                                                            ?.map((y) => {
                                                                return y.id;
                                                            })
                                                            .includes(data.id) &&
                                                        data.item_ingridient.length > 0 ? (
                                                            <div>
                                                                {menuItems.map((v) => {
                                                                    if (
                                                                        Number(v.id) ===
                                                                        Number(data.id)
                                                                    ) {
                                                                        return (
                                                                            <button
                                                                                className="mb-4 bg-transparent border-0"
                                                                                key={v.id}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        v?.extraItem
                                                                                            ?.length >
                                                                                        0
                                                                                    ) {
                                                                                        setItemChecked(
                                                                                            v.extraItem,
                                                                                        );
                                                                                    } else {
                                                                                        setItemChecked(
                                                                                            [],
                                                                                        );
                                                                                    }
                                                                                    setModal(v);
                                                                                    handleShow();
                                                                                }}
                                                                            >
                                                                                <span className="TextSmall ">
                                                                                    <FormattedMessage
                                                                                        id="RestaurentDetails.Customize"
                                                                                        defaultMessage="Customize"
                                                                                    />{' '}
                                                                                </span>
                                                                                <img
                                                                                    src={customize}
                                                                                    alt="customize"
                                                                                    width="6px"
                                                                                />
                                                                            </button>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {menuItems?.length > 0 ? (
                <div className=" mt-4">
                    <div className="col-lg-12">
                        <button
                            className="btn btn-danger BtnCheckOut"
                            onClick={() => checkOutPage()}
                        >
                            <FormattedMessage
                                id="RestaurentDetails.Checkout"
                                defaultMessage="Checkout"
                            />{' '}
                            <img src={nextImg} alt="next" width="18px" />
                        </button>
                    </div>
                </div>
            ) : (
                ''
            )}
            <Modal show={show} onHide={handleShow}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">
                            <FormattedMessage
                                id="RestaurentDetails.Select Toppings"
                                defaultMessage="Select Toppings"
                            />
                        </h4>
                        <button type="button" className="close" onClick={handleClose}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body text-left">
                        <div
                            className="card mb-3"
                            style={{
                                maxWidth: 540,
                            }}
                        >
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <img
                                        src={modal.picture}
                                        alt="Topings"
                                        className="ImgWidthToping"
                                        style={{
                                            width: '128px',
                                            height: '126px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title TitleResp">{modal.name}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="card mb-3"
                            style={{
                                maxWidth: 540,
                            }}
                        >
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="ToppingSelect">
                                        <span className="SelectTopingsHead">
                                            <FormattedMessage
                                                id="RestaurentDetails.Select Toppings"
                                                defaultMessage="Select Toppings"
                                            />
                                            :
                                        </span>
                                        {modal?.item_ingridient?.map((ig) => {
                                            const checked = menuItems?.find(
                                                (x) => Number(x.id) === Number(modal.id),
                                            );
                                            const ck = checked?.extraItem?.map((v) => {
                                                return v.id;
                                            });
                                            return (
                                                <div className="CheckboxTopings" key={ig.id}>
                                                    <input
                                                        type="checkbox"
                                                        name=""
                                                        defaultChecked={ck?.includes(ig.id)}
                                                        className="CheckToping"
                                                        value={ig.id}
                                                        onChange={(e) =>
                                                            handleChangeCheckBox(
                                                                e,
                                                                ig,
                                                                ig.extraItem,
                                                            )
                                                        }
                                                    />{' '}
                                                    <span>{ig.name}</span>
                                                    <span className="FloatRightTopings">
                                                        <img
                                                            src={rupee}
                                                            alt=""
                                                            className="RupeeImg"
                                                        />{' '}
                                                        {ig.price}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer ModalHomeFooterProfile">
                        <div className="floatRightButton">
                            <button
                                type="button"
                                className="btn btn-danger btnradius"
                                data-dismiss="modal"
                                onClick={() => addItemClick(modal.id)}
                            >
                                <FormattedMessage
                                    id="RestaurentDetails.Add Item"
                                    defaultMessage="Add Item"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default RestaurantMenuItem;
