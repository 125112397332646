import { postApi } from '../../api';
import { GET_IN_TOUCH_REQUES, GET_IN_TOUCH_SUCCESS, GET_IN_TOUCH_FAILURE } from '../../types/types';

export const getInTouchRequest = () => ({
    type: GET_IN_TOUCH_REQUES,
});

export const getInTouchSuccess = (payload) => ({
    type: GET_IN_TOUCH_SUCCESS,
    payload,
});

export const getInTouchFailure = (error) => ({
    type: GET_IN_TOUCH_FAILURE,
    error,
});

export const getInTouch = (data) => async (dispatch) => {
    dispatch(getInTouchRequest());
    return postApi(`get-in-touch`, data)
        .then((res) => {
            dispatch(getInTouchSuccess(res.data));
            return res ?? res?.data ?? res?.data?.responseData ?? null;
        })
        .catch((e) => {
            dispatch(getInTouchFailure(e));
        });
};
