import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getItem, setItem } from '../../utils/utils';

const PrivateRouting = () => {
    const token = getItem('token');
    const location = useLocation();
    if (location.pathname.length > 0) {
        setItem('path', JSON.stringify(location.pathname));
    }
    return token ? <Outlet /> : <Navigate to={`/?redirect=${location.pathname}`} />;
};

export default PrivateRouting;
