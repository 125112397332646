import { STATE_DATA_REQUEST, STATE_DATA_SUCCESS, STATE_DATA_FAILURE } from '../../types/types';

const initialState = {
    loading: false,
    payload: [],
    error: '',
};

const statesDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATE_DATA_REQUEST:
            return {
                loading: true,
                payload: [],
                error: '',
            };
        case STATE_DATA_SUCCESS:
            return {
                loading: false,
                payload: action.payload,
                error: '',
            };
        case STATE_DATA_FAILURE:
            return {
                loading: false,
                payload: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export default statesDataReducer;
