const options = {
    debounceTime: 1000,
    dateFormat: 'DD.MM.YYYY',
    dateTimeFormat: 'DD.MM.YYYY HH:mm',
};

const config = {
    API_URL: process.env.REACT_APP_API_URL,
    options,
    // production: {
    //     API_URL: 'https://api.tastesonway.com/api/v2/', //http://192.168.1.26:24/api/v2/
    //     options,
    // },
    // staging: {
    //     API_URL: 'https://dev-api.tastesonway.com/api/v2/',
    //     options,
    // },
    // local: {
    //     API_URL: 'http://localhost/taste-on-way-api/public/api/v2/',
    //     options,
    // },
};
console.log('REACT_APP_API_URL', process.env.REACT_APP_API_URL);

export const allConfig = config;

// const environmentConfig = 'production';

export default config;
