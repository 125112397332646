import { postApi } from '../../api';
import {
    AREA_DATA_REQUEST,
    AREA_DATA_SUCCESS,
    AREA_DATA_FAILURE,
    AREA_DATA_CLEAR,
} from '../../types/types';

export const areaDataRequest = () => ({
    type: AREA_DATA_REQUEST,
});
export const areaDataSuccess = (payload) => ({
    type: AREA_DATA_SUCCESS,
    payload,
});
export const areaDataFailure = (error) => ({
    type: AREA_DATA_FAILURE,
    error,
});
export const clearAreaData = () => ({
    type: AREA_DATA_CLEAR,
});

export const fetchAreaData = (data) => async (dispatch) => {
    dispatch(areaDataRequest());
    return postApi(`get-city-area`, data)
        .then((res) => {
            dispatch(areaDataSuccess(res.data));
            return res ?? res?.data ?? res?.data?.responseData ?? null;
        })
        .catch((e) => {
            dispatch(areaDataFailure(e));
        });
};
