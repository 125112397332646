import React from 'react';
import { FormattedMessage } from 'react-intl';
import feedback from '../../../assets/images/give us your feedback.png';
import './Review.css';

const Review = () => {
    return (
        <div>
            <div
                className="card ImgProfileBg"
                data-toggle="modal"
                data-target="#exampleModalreview"
            >
                <img src={feedback} className="card-img mb-5" alt="feedback" />
                <div className="card-img-overlay ImgOverlayProf">
                    <h5 className="card-title">
                        <FormattedMessage
                            id="UserProfile_page.GIVE US YOUR FOOD FEEDBACK"
                            defaultMessage="GIVE US YOUR <br /> FOOD FEEDBACK"
                            values={{ br: <br /> }}
                        />
                    </h5>
                    <p className="card-text ">
                        <FormattedMessage
                            id="UserProfile_page.Your word makes tastes on way a better place."
                            defaultMessage="Your word makes tastes <br /> on way a better place."
                            values={{ br: <br /> }}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Review;
