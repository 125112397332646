import React, { useState } from 'react';
import { updateUserProfile } from '../../../redux/actions/user/updateUserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import addimage from '../../../assets/images/addimg.png';
import './Profile.css';
import { Modal } from 'react-bootstrap';

const Profile = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState();

    const { userInfo } = useSelector((state) => ({
        userInfo: state?.userInfo?.payload?.data,
    }));
    const handleClose = () => {
        setShow(false);
        formik.resetForm();
    };
    const handleShow = () => setShow(!show);
    const formik = useFormik({
        initialValues: {
            file: null,
        },
        validationSchema: Yup.object({
            file: Yup.mixed()
                .required('File is required')
                .test('file-type', 'Invalid file type', (value) => {
                    if (!value) return true;
                    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
                    return allowedTypes.includes(value.type);
                }),
        }),
        onSubmit: () => {
            handleSubmit();
        },
    });
    const handleSubmit = () => {
        const value = formik.getFieldProps();
        const form = new FormData();
        form.append('avatar', value?.value?.file);
        dispatch(updateUserProfile(form));
        handleClose();
        formik.resetForm();
    };

    return (
        <>
            <div className="container">
                <div className="row ProfileImgResp">
                    <div className="col-lg-12">
                        <img
                            style={{ height: '200px', width: '200px', borderRadius: '50%' }}
                            src={
                                userInfo?.avatar ||
                                'https://via.placeholder.com/150x150?text=Default%20Image'
                            }
                            className="RestProfileImg RestProfImgadd RespProfileAddImg"
                            alt="Editprofile"
                        />

                        <button
                            style={{
                                background: 'red',
                                borderRadius: '50%',
                                border: 'none',
                                color: 'white',
                            }}
                            className="AddImg"
                            id="profile_upload_link"
                            onClick={handleShow}
                        >
                            +
                            <img src={addimage} className="AddImg" alt="AddImg" />
                        </button>
                        <Modal show={show} onHide={handleShow}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        <FormattedMessage
                                            id="UserProfile_page.Update Profile Photo"
                                            defaultMessage="Update Profile Photo"
                                        />
                                    </h5>
                                    <button type="button" className="close" onClick={handleClose}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group">
                                            <input
                                                name="file"
                                                type="file"
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        'file',
                                                        e.currentTarget.files[0],
                                                    )
                                                }
                                                className="form-control-file"
                                                id="exampleFormControlFile1"
                                                accept="image/*"
                                            />
                                            {formik.errors.file && (
                                                <p style={{ color: 'red' }}>{formik.errors.file}</p>
                                            )}
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer ModalHomeFooterProfile">
                                    <form
                                        className="floatRightButton"
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btnradius mx-2"
                                            onClick={handleClose}
                                        >
                                            <FormattedMessage
                                                id="UserProfile_page.Cancel"
                                                defaultMessage="Cancel"
                                            />
                                        </button>

                                        <button
                                            type="submit"
                                            className="btn btn-danger btnradius"
                                            data-dismiss="modal"
                                        >
                                            <FormattedMessage
                                                id="UserProfile_page.Update"
                                                defaultMessage="Update"
                                            />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
