import * as Yup from 'yup';

const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validationSchemaForSignUp = Yup.object({
    name: Yup.string().required('Please provide your name'),
    email: Yup.string().email('Enter valid email').required('Please provide your email'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10)
        .required('Please enter your mobile number'),
    countryCode: Yup.string().required('Please select'),
});

export const validationSchemaForLoginEmail = Yup.object({
    email: Yup.string().email('Enter valid email').required('Please provide your email'),
});

export const validationSchemaForLoginPhone = Yup.object({
    countryCode: Yup.string().required('Please select'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10)
        .required('Please enter your mobile number'),
});
