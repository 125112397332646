export const initialValuesForSignUp = {
    name: '',
    email: '',
    countryCode: '91,IN',
    phone: '',
};

export const initialValueLoginEmail = {
    email: '',
};
export const initialValueLoginPhone = {
    countryCode: '91,IN',
    phone: '',
};
