import {
    DELETE_USER_ADDRESS_REQUEST,
    DELETE_USER_ADDRESS_SUCCESS,
    DELETE_USER_ADDRESS_FAILURE,
} from '../../types/types';

import { postApi } from '../../api';
import Toastify from '../../../components/common/Toastify';
import {
    DELETE_USER_ADDRESS_ERROR,
    DELETE_USER_ADDRESS_SUCCESS_TOSTIFY,
    ERROR_TOASTIFY_TYPE,
    SUCCESS_TOASTIFY_TYPE,
} from '../../../utils/enum';

export const deleteAddresRequest = () => ({
    type: DELETE_USER_ADDRESS_REQUEST,
});

export const deleteAddresSuccess = (payload) => ({
    type: DELETE_USER_ADDRESS_SUCCESS,
    payload,
});

export const deleteAddressFailure = (error) => ({
    type: DELETE_USER_ADDRESS_FAILURE,
    error,
});

export const deleteUserAddress = (data) => async (dispatch) => {
    dispatch(deleteAddresRequest());
    return postApi(`delete-address`, data)
        .then((res) => {
            Toastify(DELETE_USER_ADDRESS_SUCCESS_TOSTIFY, SUCCESS_TOASTIFY_TYPE);
            dispatch(deleteAddresSuccess(res.data));
            return res ?? res?.data ?? res?.data?.responseData ?? null;
        })
        .catch((e) => {
            Toastify(DELETE_USER_ADDRESS_ERROR, ERROR_TOASTIFY_TYPE);
            dispatch(deleteAddressFailure(e));
        });
};
