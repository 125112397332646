import {
    ADD_USER_ADDRESS_REQUEST,
    ADD_USER_ADDRESS_SUCCESS,
    ADD_USER_ADDRESS_FAILURE,
} from '../../types/types';
import { postApi } from '../../api';
import {
    ADD_USER_ADDRESS_ERROR,
    ERROR_TOASTIFY_TYPE,
    SUCCESS_TOASTIFY_TYPE,
} from '../../../utils/enum';
import Toastify from '../../../components/common/Toastify';

export const addUserAddressRequest = () => ({
    type: ADD_USER_ADDRESS_REQUEST,
});

export const addUserAddressSuccess = (payload) => ({
    type: ADD_USER_ADDRESS_SUCCESS,
    payload,
});

export const addUserAddressFailure = (error) => ({
    type: ADD_USER_ADDRESS_FAILURE,
    error,
});

export const addUserAddress = (data) => async (dispatch) => {
    dispatch(addUserAddressRequest());
    return postApi(`create-or-update-address`, data)
        .then((res) => {
            Toastify(res?.data?.message, SUCCESS_TOASTIFY_TYPE);
            dispatch(addUserAddressSuccess(res.data));
            return res ?? res?.data ?? res?.data?.responseData ?? null;
        })
        .catch((e) => {
            Toastify(ADD_USER_ADDRESS_ERROR, ERROR_TOASTIFY_TYPE);
            dispatch(addUserAddressFailure(e));
        });
};
