import React from 'react';
import '../../assets/styles/style.css';
import '../../assets/styles/media.css';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div>
            <footer className="container-fluid HomeBackBlack ">
                <div className="container PaddingHome">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-md-4">
                            <ul className="ListUlFooter">
                                <span className="FontTitle">
                                    <FormattedMessage
                                        id="Footer.Top Cities"
                                        defaultMessage="Top Cities"
                                    />
                                </span>
                                <li>
                                    <button
                                        className="ListFooter border-0 bg-transparent"
                                        onClick={() => navigate('/comingSoon')}
                                    >
                                        <FormattedMessage
                                            id="Footer.Ahmedabad"
                                            defaultMessage="Ahmedabad"
                                        />
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="ListFooter border-0 bg-transparent"
                                        onClick={() => navigate('/comingSoon')}
                                    >
                                        <FormattedMessage
                                            id="Footer.Vadodara"
                                            defaultMessage="Vadodara"
                                        />
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="ListFooter border-0 bg-transparent"
                                        onClick={() => navigate('/comingSoon')}
                                    >
                                        <FormattedMessage
                                            id="Footer.Surat"
                                            defaultMessage="Surat"
                                        />
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="ListFooter border-0 bg-transparent"
                                        onClick={() => navigate('/comingSoon')}
                                    >
                                        <FormattedMessage
                                            id="Footer.Anand"
                                            defaultMessage="Anand"
                                        />
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-4 col-md-4">
                            <ul className="ListUlFooter">
                                <span className="FontTitle">
                                    <FormattedMessage
                                        id="Footer.Top Cuisines"
                                        defaultMessage="Top Cuisines"
                                    />
                                </span>
                                <li>
                                    <a className="ListFooter" href="/">
                                        <FormattedMessage
                                            id="Footer.Pizza near me"
                                            defaultMessage="Pizza near me"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a className="ListFooter" href="/">
                                        <FormattedMessage
                                            id="Footer.Chinese food near me"
                                            defaultMessage="Chinese food near me"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a className="ListFooter" href="/">
                                        <FormattedMessage
                                            id="Footer.Sushi near me"
                                            defaultMessage="Sushi near me"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a className="ListFooter" href="/">
                                        <FormattedMessage
                                            id="Footer.Cafe near me"
                                            defaultMessage="Cafe near me"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-4 col-md-4">
                            <ul className="ListUlFooter">
                                <span className="FontTitle">
                                    <FormattedMessage
                                        id="Footer.Get to Know Us"
                                        defaultMessage="Get to Know Us"
                                    />
                                </span>
                                <li>
                                    <a className="ListFooter" href="/About-Us">
                                        <FormattedMessage
                                            id="Footer.About Us"
                                            defaultMessage="About Us"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a className="ListFooter" href="/Contact-Us">
                                        <FormattedMessage
                                            id="Footer.Contect Us"
                                            defaultMessage="Contect Us"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a className="ListFooter" href="/">
                                        <FormattedMessage
                                            id="Footer.Careers"
                                            defaultMessage="Careers"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a className="ListFooter" href="/">
                                        <FormattedMessage
                                            id="Footer.Investors"
                                            defaultMessage="Investors"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a className="ListFooter" href="/">
                                        <FormattedMessage
                                            id="Footer.Company Blog"
                                            defaultMessage="Company Blogss"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="PaddingFooter">
                <div className="container">
                    <div className="Copyleft">
                        <span>
                            {' '}
                            <FormattedMessage
                                id="Footer.Copy Right"
                                defaultMessage="© 2022,Tastes On Way. All rights Reserved."
                            />
                        </span>
                    </div>
                    <div className="text-white">
                        <a href="https://www.tastesonway.com/Terms-of-Service" target="_new">
                            Terms Of service |
                        </a>
                        <a href="https://www.tastesonway.com/Privacy-Policy" target="_new">
                            {' '}
                            Privacy Policy |
                        </a>
                        <a href="https://www.tastesonway.com/Guidelines-and-Policies" target="_new">
                            {' '}
                            Guidelines and Policies{' '}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
