import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getItem } from '../../utils/utils';

const PublicRoute = () => {
    const token = getItem('token');
    const pathRedirect = JSON.parse(getItem('path'));
    return token ? <Navigate to={pathRedirect ? pathRedirect : '/landingPage'} /> : <Outlet />;
};

export default PublicRoute;
