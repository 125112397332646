import React, { useState, useEffect, useMemo, useCallback } from 'react';
import * as yup from 'yup';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { addUserAddress } from '../../../redux/actions/user/addUserAddress';
import { deleteUserAddress } from '../../../redux/actions/user/deleteUserAddress';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import addAddress from '../../../assets/images/add_address.png';
import './Address.css';
import { getItem, removeItem, setItem } from '../../../utils/utils';
import { fetchsetUserAddress } from '../../../redux/actions/user/setDeliveryAddress';
import { clearAreaData, fetchAreaData } from '../../../redux/actions/countryData/areaData';
import { fetchStatesData } from '../../../redux/actions/countryData/statesData';
import { clearCityData, fetchCityData } from '../../../redux/actions/countryData/cityData';

const Address = ({ intl }) => {
    const zip = intl.formatMessage({ id: 'Place_holder.380015', defaultMessage: 'eg.: 380015' });
    // const city = intl.formatMessage({ id: 'Place_holder.Ahmedabad', defaultMessage: 'Ahmedabad' });
    const addressPlaceHolder = intl.formatMessage({
        id: 'Place_holder.1006, hospitality exacel',
        defaultMessage: '1006, hospitality exacel',
    });
    const options = {
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['establishment'],
    };

    const dispatch = useDispatch();
    const [edit, setEdit] = useState();
    const [deleteId, setdeleteId] = useState();
    const [stateId, setStateId] = useState();
    const [areaId, setAreaId] = useState();
    const [mapShow, setMapShow] = useState(true);
    const [deleteShow, setDeleteShow] = useState(false);
    const [show, setShow] = useState(false);
    const [address, setAddress] = useState();
    const [currentLocation, setCurrentLocation] = useState();

    const handleShow = () => setShow(!show);
    const handleDeleteShow = () => setDeleteShow(true);
    const handleDeleteClose = () => {
        setdeleteId();
        setDeleteShow(false);
    };
    const lat = getItem('lat');
    const long = getItem('long');
    const userAddress = JSON.parse(getItem('userAddress'));

    const { cityData, areaData, statesData } = useSelector((state) => ({
        statesData: state?.statesData?.payload?.data,
        cityData: state?.cityData?.payload?.data,
        areaData: state?.areaData?.payload?.data,
    }));
    const [selectedState, setSelectedState] = useState(
        edit?.state?.name ? edit.state?.name : statesData?.[0]?.name,
    );

    const handleClose = () => {
        setShow(false);
        setMapShow(true);
        setStateId();
        setAreaId();
        dispatch(clearCityData());
        dispatch(clearAreaData());
    };
    const deleteAddress = () => {
        const addId = {
            address_id: deleteId,
        };
        dispatch(deleteUserAddress(addId));
    };

    const formik = useFormik({
        initialValues: {
            state_id: selectedState,
            StreetAddress: edit?.address ? edit.address : '',
            Area: edit?.area ? edit.area : areaData?.[0]?.name,
            Landmark: edit?.land_mark ? edit.land_mark : '',
            City: edit?.city.id ? edit.city.id : cityData?.[0]?.id,
            Zip: edit?.pin_code ? edit.pin_code : '',
            AddressType: edit?.address_type
                ? edit.address_type === 'Work'
                    ? '2'
                    : edit.address_type === 'Home'
                    ? '1'
                    : '3'
                : '1',
        },

        validationSchema: yup.object({
            state_id: yup.string().required('States is required'),
            StreetAddress: yup.string().required('Address is Required'),
            Area: yup.string().required('Area is Required'),
            Landmark: yup.string().required('Landmark is Required'),
            City: yup.string().required('City is Required'),
            Zip: yup.string().max(6, 'Please Enter Valid Zip Code').required('Zip is Required'),
            AddressType: yup.string().required('Please Select Address Type'),
        }),
        // enableReinitialize: true,
        onSubmit: (values) => {
            const editApiData = {
                address_id: edit?.id,
                state_id: values.state_id,
                city_id: values.City,
                address: values.StreetAddress,
                area: values.Area,
                land_mark: values.Landmark,
                pin_code: values.Zip,
                address_type: values.AddressType,
                latitude: lat,
                longitude: long,
            };
            const addApiData = {
                state_id: values.state_id,
                city_id: values.City,
                address: values.StreetAddress,
                area: values.Area,
                land_mark: values.Landmark,
                pin_code: values.Zip,
                address_type: values.AddressType,
                latitude: lat,
                longitude: long,
            };

            const apiData = edit ? editApiData : addApiData;
            dispatch(addUserAddress(apiData));
            setSelectedState();
            handleClose();
            setEdit();
            setShow(!show);
            removeItem('address');
        },
    });

    useEffect(() => {
        const { address, state, area, land_mark, city, pin_code, address_type } = edit ?? {};

        formik.setValues({
            StreetAddress: address,
            state_id: state?.id,
            Area: area || areaData?.[0]?.name,
            Landmark: land_mark,
            City: city?.id,
            Zip: pin_code,
            AddressType: address_type === 'Work' ? '2' : address_type === 'Home' ? '1' : '3' || '1',
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit]);

    const handelChangeArea = (value) => {
        const id = cityData?.find((d) => d.id == value);
        setAreaId(id.id);
    };
    const handelChangeState = (value) => {
        setSelectedState(value);
        setStateId(value);
    };

    const handelDeliverHere = (id) => {
        const apiData = {
            address_id: id,
        };
        dispatch(fetchsetUserAddress(apiData));
    };

    useEffect(() => {
        const storedLat = getItem('lat');
        const storedLng = getItem('long');
        if (storedLat && storedLng) {
            setAddress(getItem('address'));
            setCurrentLocation({
                lat: parseFloat(storedLat),
                lng: parseFloat(storedLng),
            });
        } else if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    setItem('lat', position.coords.latitude.toString());
                    setItem('long', position.coords.longitude.toString());
                },
                (error) => {
                    setItem('lat', 23.012);
                    setItem('long', 72.5108);
                    return error;
                },
                {
                    enableHighAccuracy: true,
                },
            );
        }
    }, []);
    const handleDrag = useCallback((event) => {
        const lat = event.latLng.lat().toString();
        const lng = event.latLng.lng().toString();
        setItem('lat', lat);
        setItem('long', lng);
    }, []);

    const handleSelect = useCallback(async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);

        setAddress(value);
        setCurrentLocation(latLng);
        setItem('lat', latLng.lat.toString());
        setItem('long', latLng.lng.toString());
    }, []);

    const center = useMemo(
        () => ({
            lat: parseFloat(getItem('lat')) || 23.012,
            lng: parseFloat(getItem('long')) || 72.5108,
        }),
        [],
    );

    const MapWithAMarker = useMemo(
        () =>
            withGoogleMap(() => (
                <GoogleMap
                    defaultZoom={17}
                    defaultCenter={
                        currentLocation || {
                            lat: Number(lat) || 23.012,
                            lng: Number(long) || 72.5108,
                        }
                    }
                >
                    <Marker
                        draggable={true}
                        onDrag={handleDrag}
                        position={{
                            lat: Number(lat) || 23.012,
                            lng: Number(long) || 72.5108,
                        }}
                    />
                </GoogleMap>
            )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [center, handleDrag, lat, long],
    );

    useEffect(() => {
        const id = getItem('country_id');
        const apiData = {
            country_id: id,
        };
        dispatch(fetchStatesData(apiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        const state_id = JSON.parse(getItem('statesData'));
        const apiData = {
            state_id: stateId ? stateId : state_id?.[0]?.id,
        };
        dispatch(fetchCityData(apiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateId]);

    useEffect(() => {
        const cityId = JSON.parse(getItem('cityData'));
        const apiData = {
            city_id: areaId ? areaId : cityId?.data?.[0]?.id,
        };

        dispatch(fetchAreaData(apiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areaId, cityData]);

    const handleNext = () => {
        setMapShow(false);
    };
    return (
        <>
            <div
                className="tab-pane fade ScrollbarHidden"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
            >
                <h2 className="respmargtopprof RespMargTop">
                    <FormattedMessage
                        id="UserProfile_page.My Address"
                        defaultMessage="My Address"
                    />
                </h2>

                <div className="row">
                    {userAddress?.data?.map((data) => {
                        return (
                            <div className="col-lg-6" key={data.id}>
                                <div className="card mt-4">
                                    <div className="card-body ProfileCardBody">
                                        <h5 className="card-title">{data.address_type}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">
                                            {data.address},{data.area}
                                        </h6>
                                        <p className="card-text">
                                            {data.city.name} - {data.pin_code}
                                        </p>
                                        <button
                                            onClick={() => {
                                                setEdit(data);
                                                setMapShow(false);
                                                setSelectedState(data.state.id);
                                                setStateId(data.state_id);
                                                handleShow(data.id);
                                            }}
                                            type="button"
                                            className="card-link cardProfilelink"
                                            data-toggle="modal"
                                            data-target="#exampleModalAddAddress"
                                        >
                                            <FormattedMessage
                                                id="UserProfile_page.Edit"
                                                defaultMessage="Edit"
                                            />
                                        </button>
                                        <button
                                            onClick={() => {
                                                setdeleteId(data.id);
                                                handleDeleteShow();
                                            }}
                                            className="card-link ml-4 cardProfilelink"
                                        >
                                            <FormattedMessage
                                                id="UserProfile_page.Delete"
                                                defaultMessage="Delete"
                                            />
                                        </button>
                                        {data.is_delivery_address == 'Yes' ? (
                                            <button className="card-link ml-4 border-0" disabled>
                                                <FormattedMessage
                                                    id="UserProfile_page.Default"
                                                    defaultMessage="Default"
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => {
                                                    handelDeliverHere(data.id);
                                                }}
                                                className="card-link ml-4 cardProfilelink"
                                            >
                                                <FormattedMessage
                                                    id="UserProfile_page.Set Default"
                                                    defaultMessage="Set Default"
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <Modal show={deleteShow} onHide={handleDeleteClose} backdrop="static">
                        <Modal.Header>
                            <Modal.Title>
                                <FormattedMessage
                                    id="UserProfile_page.Delete Address"
                                    defaultMessage="Delete Address"
                                />
                            </Modal.Title>
                            <button type="button" className="close" onClick={handleDeleteClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage
                                id="UserProfile_page.Are You Sure You Want to Delete This Address
                                                Permanently?"
                                defaultMessage="Are You Sure You Want to Delete This Address
                                                Permanently?"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn mr-2 btn-outline-danger btnradius"
                                onClick={handleDeleteClose}
                            >
                                <FormattedMessage id="UserProfile_page.NO" defaultMessage="NO" />
                            </button>
                            <button
                                onClick={() => {
                                    deleteAddress();
                                    handleDeleteClose();
                                }}
                                className="btn btn-danger btnradius"
                            >
                                <FormattedMessage id="UserProfile_page.YES" defaultMessage="YES" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <div className="col-lg-6">
                        <div className="card mt-4">
                            <div className="card-body ProfileCardBody text-center">
                                <div className="CursorPoint">
                                    <Button
                                        style={{
                                            background: 'white',
                                            border: 'none',
                                            color: 'black',
                                        }}
                                        onClick={() => handleShow()}
                                    >
                                        <img
                                            src={addAddress}
                                            className="IconAddress mt-4"
                                            alt="addAddress"
                                        />
                                        <h5 className="card-title mt-2">
                                            <FormattedMessage
                                                id="UserProfile_page.Add New Address"
                                                defaultMessage="Add New Address"
                                            />
                                        </h5>
                                    </Button>
                                </div>

                                <Modal show={show} onHide={handleClose} animation={false}>
                                    <div className="modal-content ContentLeft">
                                        <div className="modal-header">
                                            {edit ? (
                                                <h4 className="modal-title" id="exampleModalLabel">
                                                    <FormattedMessage
                                                        id="UserProfile_page.Edit Address"
                                                        defaultMessage="Edit Address"
                                                    />
                                                </h4>
                                            ) : (
                                                <h4 className="modal-title" id="exampleModalLabel">
                                                    <FormattedMessage
                                                        id="UserProfile_page.Add New Address"
                                                        defaultMessage="Add New Address"
                                                    />
                                                </h4>
                                            )}

                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span
                                                    onClick={() => {
                                                        setEdit();
                                                        setShow(!show);
                                                        handleClose();
                                                    }}
                                                    aria-hidden="true"
                                                >
                                                    ×
                                                </span>
                                            </button>
                                        </div>
                                        {mapShow ? (
                                            <div className="p-4">
                                                <MapWithAMarker
                                                    containerElement={
                                                        <div style={{ height: `400px` }} />
                                                    }
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                />
                                                <label className="mt-4" htmlFor="inputAddress">
                                                    Search
                                                </label>
                                                <div>
                                                    <PlacesAutocomplete
                                                        className="placeInput"
                                                        value={address}
                                                        onChange={setAddress}
                                                        onSelect={handleSelect}
                                                        searchOptions={options}
                                                    >
                                                        {({
                                                            getInputProps,
                                                            suggestions,
                                                            getSuggestionItemProps,
                                                            loading,
                                                        }) => (
                                                            <div>
                                                                <input
                                                                    id="inputAddress"
                                                                    style={{
                                                                        marginTop: '5px',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        width: '100%',
                                                                        height: '40px',
                                                                        fontSize: '16px',
                                                                        padding: '10px',
                                                                        borderRadius: '5px',
                                                                        border: '1px solid #ccc',
                                                                    }}
                                                                    className="placeInput"
                                                                    {...getInputProps({
                                                                        placeholder:
                                                                            'Enter your address',
                                                                    })}
                                                                />
                                                                <div className="divy">
                                                                    {loading ? (
                                                                        <div>...loading</div>
                                                                    ) : null}
                                                                    {suggestions.map(
                                                                        (suggestion) => {
                                                                            const style = {
                                                                                backgroundColor:
                                                                                    suggestion.active
                                                                                        ? '#dc3545'
                                                                                        : '#fff',
                                                                                borderStyle:
                                                                                    'hidden',
                                                                                color: suggestion.active
                                                                                    ? '#fff'
                                                                                    : 'black',
                                                                                padding: '5px',
                                                                            };
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        suggestion.index
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className="suggestionInput"
                                                                                        {...getSuggestionItemProps(
                                                                                            suggestion,
                                                                                            {
                                                                                                style,
                                                                                            },
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            suggestion.description
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger mt-3"
                                                    onClick={() => {
                                                        handleNext();
                                                    }}
                                                >
                                                    Next
                                                </button>
                                                <div className="modal-footer ModalHomeFooterProfile" />
                                            </div>
                                        ) : (
                                            <>
                                                {' '}
                                                <div className="modal-body">
                                                    <div className="row AddAddressModal">
                                                        <div className="col-lg-12">
                                                            <form onSubmit={formik.handleSubmit}>
                                                                <div className="form-group">
                                                                    <label htmlFor="StreetAddress">
                                                                        <FormattedMessage
                                                                            id="UserProfile_page.StreetAddress"
                                                                            defaultMessage="Address"
                                                                        />
                                                                    </label>
                                                                    <input
                                                                        name="StreetAddress"
                                                                        type="text"
                                                                        className="form-control AddAddressModal"
                                                                        id="StreetAddress"
                                                                        placeholder={
                                                                            addressPlaceHolder
                                                                        }
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        value={
                                                                            formik.values
                                                                                .StreetAddress
                                                                        }
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: 'red',
                                                                        }}
                                                                    >
                                                                        {
                                                                            formik.errors
                                                                                .StreetAddress
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="inputState">
                                                                        <FormattedMessage
                                                                            id="UserProfile_page.States"
                                                                            defaultMessage="States"
                                                                        />
                                                                    </label>
                                                                    <select
                                                                        name="state_id"
                                                                        className="form-control AddAddressModal"
                                                                        id="inputState"
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                            handelChangeState(
                                                                                e.target.value,
                                                                            );
                                                                        }}
                                                                        value={
                                                                            formik.values.state_id
                                                                        }
                                                                    >
                                                                        <option
                                                                            value={true}
                                                                            disabled={true}
                                                                            selected={true}
                                                                        >
                                                                            Select State
                                                                        </option>
                                                                        {statesData?.map((d) => {
                                                                            return (
                                                                                <option
                                                                                    value={d.id}
                                                                                    key={d.id}
                                                                                >
                                                                                    {d.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    {formik.errors.state_id &&
                                                                        formik.touched.state_id && (
                                                                            <p
                                                                                style={{
                                                                                    color: 'red',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    formik.errors
                                                                                        .state_id
                                                                                }
                                                                            </p>
                                                                        )}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="inputCity">
                                                                        <FormattedMessage
                                                                            id="UserProfile_page.City"
                                                                            defaultMessage="City"
                                                                        />
                                                                    </label>
                                                                    <select
                                                                        name="City"
                                                                        className="form-control AddAddressModal"
                                                                        id="inputCity"
                                                                        placeholder="Select city"
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                            handelChangeArea(
                                                                                e.target.value,
                                                                            );
                                                                        }}
                                                                        value={formik.values.City}
                                                                    >
                                                                        <option
                                                                            value={true}
                                                                            disabled={true}
                                                                            selected={true}
                                                                        >
                                                                            Select City
                                                                        </option>
                                                                        {cityData?.map((d) => {
                                                                            return (
                                                                                <option
                                                                                    value={d.id}
                                                                                    key={d.id}
                                                                                >
                                                                                    {d.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    {formik.errors.City &&
                                                                        formik.touched.City && (
                                                                            <p
                                                                                style={{
                                                                                    color: 'red',
                                                                                }}
                                                                            >
                                                                                {formik.errors.City}
                                                                            </p>
                                                                        )}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="inputAddress2">
                                                                        <FormattedMessage
                                                                            id="UserProfile_page.Landmark"
                                                                            defaultMessage="Landmark"
                                                                        />
                                                                    </label>
                                                                    <input
                                                                        name="Landmark"
                                                                        type="text"
                                                                        className="form-control AddAddressModal"
                                                                        id="inputAddress2"
                                                                        placeholder="Enter your near place"
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        value={
                                                                            formik.values.Landmark
                                                                        }
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    {formik.errors.Landmark &&
                                                                        formik.touched.Landmark && (
                                                                            <p
                                                                                style={{
                                                                                    color: 'red',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    formik.errors
                                                                                        .Landmark
                                                                                }
                                                                            </p>
                                                                        )}
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label htmlFor="inputAddress2">
                                                                            <FormattedMessage
                                                                                id="UserProfile_page.Area"
                                                                                defaultMessage="Area"
                                                                            />
                                                                        </label>
                                                                        <select
                                                                            name="Area"
                                                                            className="form-control AddAddressModal"
                                                                            id="inputCity"
                                                                            onChange={
                                                                                formik.handleChange
                                                                            }
                                                                            value={
                                                                                formik.values.Area
                                                                            }
                                                                        >
                                                                            <option
                                                                                value={true}
                                                                                disabled={true}
                                                                                selected={true}
                                                                            >
                                                                                Select Area
                                                                            </option>
                                                                            {areaData?.map((d) => {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            d.name
                                                                                        }
                                                                                        key={d.id}
                                                                                    >
                                                                                        {d.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                        {formik.errors.Area &&
                                                                            formik.touched.Area && (
                                                                                <p
                                                                                    style={{
                                                                                        color: 'red',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        formik
                                                                                            .errors
                                                                                            .Area
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                    </div>

                                                                    <div className="form-group col-md-6">
                                                                        <label htmlFor="inputZip">
                                                                            <FormattedMessage
                                                                                id="UserProfile_page.Zip"
                                                                                defaultMessage="Zip"
                                                                            />
                                                                        </label>
                                                                        <input
                                                                            name="Zip"
                                                                            type="text"
                                                                            className="form-control AddAddressModal"
                                                                            id="inputZip"
                                                                            placeholder={zip}
                                                                            onChange={
                                                                                formik.handleChange
                                                                            }
                                                                            value={
                                                                                formik.values.Zip
                                                                            }
                                                                            onBlur={
                                                                                formik.handleBlur
                                                                            }
                                                                        />
                                                                        {formik.errors.Zip &&
                                                                            formik.touched.Zip && (
                                                                                <p
                                                                                    style={{
                                                                                        color: 'red',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        formik
                                                                                            .errors
                                                                                            .Zip
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label htmlFor="addresstype">
                                                                            <FormattedMessage
                                                                                id="UserProfile_page.Address Type"
                                                                                defaultMessage="Address Type"
                                                                            />
                                                                        </label>
                                                                        <select
                                                                            name="AddressType"
                                                                            id="addresstype"
                                                                            className="form-control"
                                                                            onChange={
                                                                                formik.handleChange
                                                                            }
                                                                            value={
                                                                                formik.values
                                                                                    .AddressType
                                                                            }
                                                                        >
                                                                            <option value="1">
                                                                                <FormattedMessage
                                                                                    id="UserProfile_page.Home"
                                                                                    defaultMessage="Home"
                                                                                />
                                                                            </option>
                                                                            <option value="2">
                                                                                <FormattedMessage
                                                                                    id="UserProfile_page.Work"
                                                                                    defaultMessage="Work"
                                                                                />
                                                                            </option>
                                                                            <option value="3">
                                                                                <FormattedMessage
                                                                                    id="UserProfile_page.Other"
                                                                                    defaultMessage="Other"
                                                                                />
                                                                            </option>
                                                                        </select>
                                                                        {formik.errors
                                                                            .AddressType &&
                                                                            formik.touched
                                                                                .AddressType && (
                                                                                <p
                                                                                    style={{
                                                                                        color: 'red',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        formik
                                                                                            .errors
                                                                                            .AddressType
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    className="btn btn-danger AddAddressModal mr-3"
                                                                    onClick={() => setMapShow(true)}
                                                                >
                                                                    Previous
                                                                </button>

                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-danger AddAddressModal"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <FormattedMessage
                                                                        id="UserProfile_page.Add Address"
                                                                        defaultMessage="Add Address"
                                                                    />
                                                                </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer ModalHomeFooterProfile" />{' '}
                                            </>
                                        )}
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default injectIntl(Address);
