import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import {
    fetchCreateOrderDetails,
    fetchDeliveryPersonLocation,
} from '../../services/orederDetails/OrderDetails';
import dileveryBoy from '../../assets/mapicons/deliveryboy.png';
import user from '../../assets/mapicons/home-delivery.png';
import restaurant from '../../assets/mapicons/kitchen-owner.png';
import phoneCall from '../../../src/assets/images/phone-call.png';
import timer from '../../assets/mapicons/timer-icon.png';
import './TrackOrder.css';
import Pusher from 'pusher-js';

const TrackOrder = ({ data }) => {
    const directionService = useRef(null);
    const dispatch = useDispatch();
    const [orderDetails, setOrderDetails] = useState();
    const [kitchenOwnerLocation, setKitchenOwnerLocation] = useState();
    const [endUserLocation, setEndUserLocation] = useState();
    const [dileveryPersonAssign, setDileveryPersonAssign] = useState();
    const [dileveryPersonId, setDileveryPersonId] = useState();
    const [mapCenter, setMapCenter] = useState();
    const [orderStatus, setOrderStatus] = useState();
    const [time, setTime] = useState();
    const [deliveryBoyLocation, setDeliveryBoyLocation] = useState({
        lat: 0,
        lng: 0,
    });
    const [restaurantLocation, setRestaurantLocation] = useState({
        lat: 0,
        lng: 0,
    });
    const [userLocation, setUserLocation] = useState({
        lat: 0,
        lng: 0,
    });
    const [route, setRoute] = useState([]);

    const orderId = data?.id;

    const dataa = data;

    const [status, setStatus] = useState(dataa.order_status);

    useEffect(() => {
        const pusher = new Pusher('9edf8c2026f57e4d7421', {
            cluster: 'ap2',
        });
        const channel1 = pusher.subscribe('taste-on-way-demo');
        channel1.bind(orderId, function (data) {
            setStatus(data.order_status);
        });

        return () => {
            pusher.unsubscribe('taste-on-way-demo');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchData() {
            const res = await dispatch(fetchCreateOrderDetails({ order_id: orderId }));
            setOrderDetails(res.data.data);
            setOrderStatus(res.data.data.order_status);
            setEndUserLocation({
                lat: Number(res.data.data.user_address.latitude),
                lng: Number(res.data.data.user_address.longitude),
            });
            setKitchenOwnerLocation({
                lat: Number(res.data.data.business_owner_address.latitude),
                lng: Number(res.data.data.business_owner_address.longitude),
            });
            setDileveryPersonAssign(res.data.data.delivery_person);
            setDileveryPersonId(res.data.data.delivery_person?.id || null);
            if (dileveryPersonId && res.data.data.delivery_person) {
                const data = {
                    delivery_person_id: dileveryPersonId,
                };
                dispatch(fetchDeliveryPersonLocation(data)).then((res) => {
                    setDeliveryBoyLocation(res.data.data.getLocationData);
                    setMapCenter(res.data.data.getLocationData);
                });
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dileveryPersonId]);

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
    };

    const toRadians = (angle) => (angle * Math.PI) / 180;

    const calculateDistance = (location1, location2) => {
        const R = 6371; // Earth's radius in km
        const dLat = toRadians(location2.lat - location1.lat);
        const dLon = toRadians(location2.lng - location1.lng);
        const lat1 = toRadians(location1.lat);
        const lat2 = toRadians(location2.lat);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in km

        return distance;
    };
    useEffect(() => {
        if (kitchenOwnerLocation && endUserLocation) {
            handleApiLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kitchenOwnerLocation, endUserLocation]);

    const handleApiLoad = () => {
        const deliveryBoyLocationData = {
            lat: deliveryBoyLocation?.lat,
            lng: deliveryBoyLocation?.long,
        };
        const restaurantLocationData = {
            lat: kitchenOwnerLocation?.lat,
            lng: kitchenOwnerLocation?.lng,
        };

        const userLocationData = {
            lat: endUserLocation?.lat,
            lng: endUserLocation?.lng,
        };

        const distance = calculateDistance(
            deliveryBoyLocationData,
            restaurantLocationData,
            userLocationData,
        );
        // console.log(`Distance: ${Math.round(distance)} km`);
        const speed = 30; // km/hour (assumed speed)
        const time = distance / speed; // Time in hours
        const timeInMin = time * 60;
        const times = Math.round(timeInMin);
        setTime(`${times} min`);

        setDeliveryBoyLocation(deliveryBoyLocationData);
        setRestaurantLocation(restaurantLocationData);
        setUserLocation(userLocationData);
        directionService.current = new window.google.maps.DirectionsService();

        directionService.current.route(
            {
                origin: restaurantLocationData,
                destination: userLocationData,
                waypoints: [{ location: userLocationData }],
                travelMode: 'DRIVING',
            },
            (result, status) => {
                if (status === 'OK') {
                    const points = result.routes[0].overview_path.map((point) => ({
                        lat: point.lat(),
                        lng: point.lng(),
                    }));
                    setRoute(points);
                } else {
                    console.error(`Directions service failed due to ${status}`);
                }
            },
        );
    };

    const defaultCenter = mapCenter
        ? { lat: mapCenter.lat, lng: mapCenter.long }
        : { lat: kitchenOwnerLocation?.lat, lng: kitchenOwnerLocation?.lng };

    const defaultZoom = 16;

    return (
        <>
            <div className="modal-body">
                <p className="text-center">Ordered from</p>
                <h4 className="text-center">{orderDetails?.business_owner_address?.office_name}</h4>
                <h4 className="text-center mb-4 OrderColor">
                    Your Ordrer is {status || orderStatus}
                </h4>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={defaultCenter}
                    zoom={defaultZoom}
                    onLoad={handleApiLoad}
                >
                    {dileveryPersonAssign !== null && deliveryBoyLocation && (
                        <Marker
                            position={deliveryBoyLocation}
                            icon={{
                                url: dileveryBoy,
                                scaledSize: new window.google.maps.Size(40, 70),
                            }}
                        />
                    )}
                    {restaurantLocation && (
                        <Marker
                            position={restaurantLocation}
                            icon={{
                                url: restaurant,
                                scaledSize: new window.google.maps.Size(30, 30),
                            }}
                        />
                    )}
                    {userLocation && (
                        <Marker
                            position={userLocation}
                            icon={{
                                url: user,
                                scaledSize: new window.google.maps.Size(30, 30),
                            }}
                        />
                    )}
                    {route && (
                        <Polyline
                            path={route}
                            options={{
                                strokeColor: '#dc3545',
                                strokeWeight: 5,
                                strokeDasharray: [5, 5],
                            }}
                        />
                    )}
                </GoogleMap>
                <div className="row">
                    {dileveryPersonAssign !== null ? (
                        <div className="col-lg-12">
                            <div className="card mt-4">
                                <div className="card-header cardheadwhite">
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <img
                                                src={dileveryPersonAssign?.avatar}
                                                className="WidthProfImgRider"
                                                alt="rider"
                                            />
                                        </div>
                                        <div className="col-lg-6 mt-2">
                                            <div className="HeadProfBoxRider">
                                                {dileveryPersonAssign?.name &&
                                                    dileveryPersonAssign.name.toUpperCase()}
                                            </div>
                                            <a
                                                href={`tel:+91${dileveryPersonAssign?.mobile_number}`}
                                            >
                                                <div className="NormalTextProfRide mt-3">
                                                    <img
                                                        src={phoneCall}
                                                        className="WidthIconCall"
                                                        alt="phone"
                                                    />
                                                    +91 {dileveryPersonAssign?.mobile_number}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-4 TimerShow">
                                            <img
                                                src={timer}
                                                alt="Timer Icon"
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    marginRight: '20px',
                                                }}
                                            />
                                            <div className="TextEnd">
                                                <span className="TextColor TextContent EstimateTime">
                                                    Estimated Time
                                                </span>
                                                <div className="TextEnd">
                                                    <span className="TextColor TextContent EstimateColor">
                                                        {typeof time === 'number' ? time : '0 min'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="col-lg-12">
                            <div className="card mt-4">
                                <div className="card-body text-center">
                                    <h4 className="HeadColor">
                                        {' '}
                                        Delivery person will assign shortly!{' '}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-lg-12">
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="row">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <div
                                                    className="timeline-steps aos-init aos-animate"
                                                    data-aos="fade-up"
                                                >
                                                    <div
                                                        className={`timeline-step ${
                                                            status === 'Preparing' ||
                                                            status === 'Prepared' ||
                                                            status === 'On The Way' ||
                                                            status === 'Delivered'
                                                                ? 'demo'
                                                                : 'timeline-step'
                                                        } `}
                                                    >
                                                        <div
                                                            className="timeline-content"
                                                            data-toggle="popover"
                                                            data-trigger="hover"
                                                            data-placement="top"
                                                            title=""
                                                            data-content="And here's some amazing content. It's very engaging. Right?"
                                                            data-original-title="2003"
                                                        >
                                                            <div
                                                                className={`${
                                                                    status === 'Preparing' ||
                                                                    status === 'Prepared' ||
                                                                    status === 'On The Way' ||
                                                                    status === 'Delivered'
                                                                        ? 'demo'
                                                                        : 'inner-circle'
                                                                } `}
                                                            ></div>

                                                            <p className="h6 text-muted mb-0 mb-lg-0">
                                                                Accepted
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`timeline-step ${
                                                            status === 'Prepared' ||
                                                            status === 'On The Way' ||
                                                            status === 'Delivered'
                                                                ? 'demo'
                                                                : 'timeline-step'
                                                        }`}
                                                    >
                                                        <div
                                                            className="timeline-content"
                                                            data-toggle="popover"
                                                            data-trigger="hover"
                                                            data-placement="top"
                                                            title=""
                                                            data-content="And here's some amazing content. It's very engaging. Right?"
                                                            data-original-title="2004"
                                                        >
                                                            <div
                                                                className={`inner-circle ${
                                                                    status === 'Preparing'
                                                                        ? 'animated'
                                                                        : status === 'Prepared' ||
                                                                          status === 'On The Way' ||
                                                                          status === 'Delivered'
                                                                        ? 'demo'
                                                                        : ''
                                                                }`}
                                                            ></div>

                                                            <p className="h6 text-muted mb-0 mb-lg-0">
                                                                Preparing
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`timeline-step${
                                                            status === 'Prepared' ||
                                                            status === 'On The Way' ||
                                                            status === 'Delivered'
                                                                ? ' demo'
                                                                : 'timeline-step'
                                                        }`}
                                                    >
                                                        <div
                                                            className="timeline-content"
                                                            data-toggle="popover"
                                                            data-trigger="hover"
                                                            data-placement="top"
                                                            title=""
                                                            data-content="And here's some amazing content. It's very engaging. Right?"
                                                            data-original-title="2005"
                                                        >
                                                            <div
                                                                className={`${
                                                                    status === 'Prepared' ||
                                                                    status === 'On The Way' ||
                                                                    status === 'Delivered'
                                                                        ? ' demo'
                                                                        : 'inner-circle'
                                                                }`}
                                                            ></div>

                                                            <p className="h6 text-muted mb-0 mb-lg-0">
                                                                Prepared
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`timeline-step ${
                                                            status === 'Delivered'
                                                                ? 'demo'
                                                                : 'timeline-step'
                                                        }`}
                                                    >
                                                        <div
                                                            className="timeline-content"
                                                            data-toggle="popover"
                                                            data-trigger="hover"
                                                            data-placement="top"
                                                            title=""
                                                            data-content="And here's some amazing content. It's very engaging. Right?"
                                                            data-original-title="2010"
                                                        >
                                                            <div
                                                                className={`inner-circle ${
                                                                    status === 'On The Way'
                                                                        ? 'animated'
                                                                        : status === 'Delivered'
                                                                        ? 'demo'
                                                                        : ''
                                                                }`}
                                                            ></div>
                                                            <p className="h6 text-muted mb-0 mb-lg-0">
                                                                On the way
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-footer ModalHomeFooterProfile" />
        </>
    );
};

export default TrackOrder;
