import { setItem } from '../../../utils/utils';
import { postApi } from '../../api';
import { STATE_DATA_REQUEST, STATE_DATA_SUCCESS, STATE_DATA_FAILURE } from '../../types/types';

export const statesDataRequest = () => ({
    type: STATE_DATA_REQUEST,
});
export const statesDataSuccess = (payload) => ({
    type: STATE_DATA_SUCCESS,
    payload,
});
export const statesDataFailure = (error) => ({
    type: STATE_DATA_FAILURE,
    error,
});

export const fetchStatesData = (data) => async (dispatch) => {
    dispatch(statesDataRequest());
    return postApi(`get-states`, data)
        .then((res) => {
            setItem('statesData', JSON.stringify(res.data.data));
            dispatch(statesDataSuccess(res.data));
            return res ?? res?.data ?? res?.data?.responseData ?? null;
        })
        .catch((e) => {
            dispatch(statesDataFailure(e));
        });
};
