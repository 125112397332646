export const COUNTRY_DATA_REQUEST = 'COUNTRY_DATA_REQUEST';
export const COUNTRY_DATA_SUCCESS = 'COUNTRY_DATA_SUCCESS';
export const COUNTRY_DATA_FAILURE = 'COUNTRY_DATA_FAILURE';

export const CITY_DATA_REQUEST = 'CITY_DATA_REQUEST';
export const CITY_DATA_SUCCESS = 'CITY_DATA_SUCCESS';
export const CITY_DATA_FAILURE = 'CITY_DATA_FAILURE';
export const CITY_DATA_CLEAR = 'CITY_DATA_CLEAR';

export const AREA_DATA_REQUEST = 'AREA_DATA_REQUEST';
export const AREA_DATA_SUCCESS = 'AREA_DATA_SUCCESS';
export const AREA_DATA_FAILURE = 'AREA_DATA_FAILURE';
export const AREA_DATA_CLEAR = 'AREA_DATA_CLEAR';

export const STATE_DATA_REQUEST = 'STATE_DATA_REQUEST';
export const STATE_DATA_SUCCESS = 'STATE_DATA_SUCCESS';
export const STATE_DATA_FAILURE = 'STATE_DATA_FAILURE';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_CLEAN = 'USER_LOGIN_CLEAN';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';
export const USER_REGISTER_CLEAN = 'USER_REGISTER_CLEAN';

export const USER_OTP_REQUEST = 'USER_OTP_REQUEST';
export const USER_OTP_SUCCESS = 'USER_OTP_SUCCESS';
export const USER_OTP_FAILURE = 'USER_OTP_FAILURE';
export const USER_OTP_CLEAN = 'USER_OTP_CLEAN';

export const GET_KITCHEN_OWNER_REQUEST = 'GET_KITCHEN_OWNER_REQUEST';
export const GET_KITCHEN_OWNER_SUCCESS = 'GET_KITCHEN_OWNER_SUCCESS';
export const GET_KITCHEN_OWNER_FAILURE = 'GET_KITCHEN_OWNER_FAILURE';

export const GET_TOP_FIVE_RESTAURANTS_REQUEST = 'GET_TOP_FIVE_RESTAURANTS_REQUEST';
export const GET_TOP_FIVE_RESTAURANTS_SUCCESS = 'GET_TOP_FIVE_RESTAURANTS_SUCCESS';
export const GET_TOP_FIVE_RESTAURANTS_FAILURE = 'GET_TOP_FIVE_RESTAURANTS_FAILURE';

export const GET_ORDER_AGAIN_DETAILS_REQUEST = 'GET_ORDER_AGAIN_DETAILS_REQUEST';
export const GET_ORDER_AGAIN_DETAILS_SUCCESS = 'GET_ORDER_AGAIN_DETAILS_SUCCESS';
export const GET_ORDER_AGAIN_DETAILS_FAILURE = 'GET_ORDER_AGAIN_DETAILS_FAILURE';

export const GET_KITCHEN_OWNER_DETAILS_REQUEST = 'GET_KITCHEN_OWNER_DETAILS_REQUEST';
export const GET_KITCHEN_OWNER_DETAILS_SUCCESS = 'GET_KITCHEN_OWNER_DETAILS_SUCCESS';
export const GET_KITCHEN_OWNER_DETAILS_FAILURE = 'GET_KITCHEN_OWNER_DETAILS_FAILURE';

export const GET_KITCHEN_OWNER_MENU_LIST_REQUEST = 'GET_KITCHEN_OWNER_MENU_LIST_REQUEST';
export const GET_KITCHEN_OWNER_MENU_LIST_SUCCESS = 'GET_KITCHEN_OWNER_MENU_LIST_SUCCESS';
export const GET_KITCHEN_OWNER_MENU_LIST_FAILURE = 'GET_KITCHEN_OWNER_MENU_LIST_FAILURE';

export const GET_KITCHEN_OWNER_MENU_ITEM_LIST_REQUEST = 'GET_KITCHEN_OWNER_MENU_ITEM_LIST_REQUEST';
export const GET_KITCHEN_OWNER_MENU_ITEM_LIST_SUCCESS = 'GET_KITCHEN_OWNER_MENU_ITEM_LIST_SUCCESS';
export const GET_KITCHEN_OWNER_MENU_ITEM_LIST_FAILURE = 'GET_KITCHEN_OWNER_MENU_ITEM_LIST_FAILURE';
export const GET_KITCHEN_OWNER_MENU_ITEM__LIST_CLEAN = 'GET_KITCHEN_OWNER_MENU_ITEM__LIST_CLEAN';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';
export const CREATE_ORDER_CLEAN = 'CREATE_ORDER_CLEAN';

export const CREATE_ORDER_DETAILS_REQUEST = 'CREATE_ORDER_DETAILS_REQUEST';
export const CREATE_ORDER_DETAILS_SUCCESS = 'CREATE_ORDER_DETAILS_SUCCESS';
export const CREATE_ORDER_DETAILS_FAILURE = 'CREATE_ORDER_DETAILS_FAILURE';

export const GET_DELIVERY_PERSON_LOCATION_DETAILS_SUCCESS =
    'GET_DELIVERY_PERSON_LOCATION_DETAILS_SUCCESS';
export const GET_DELIVERY_PERSON_LOCATION_DETAILS_FAILURE =
    'GET_DELIVERY_PERSON_LOCATION_DETAILS_FAILURE';

export const CLEAR_ORDER_DETAILS = 'CLEAR_ORDER_DETAILS';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILURE = 'GET_ORDER_DETAILS_FAILURE';
export const GET_ORDER_DETAILS_REQUEST = 'GET_ORDER_DETAILS_REQUEST';

export const CREATE_ORDER_TRANSACTION_REQUEST = 'CREATE_ORDER_TRANSACTION_REQUEST';
export const CREATE_ORDER_TRANSACTION_SUCCESS = 'CREATE_ORDER_TRANSACTION_SUCCESS';
export const CREATE_ORDER_TRANSACTION_FAILURE = 'CREATE_ORDER_TRANSACTION_FAILURE';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';

export const VIEW_CART_REQUEST = 'VIEW_CART_REQUEST';
export const VIEW_CART_SUCCESS = 'VIEW_CART_SUCCESS';
export const VIEW_CART_FAILURE = 'VIEW_CART_FAILURE';

export const GET_PROMO_CODE_REQUEST = 'GET_PROMO_CODE_REQUEST';
export const GET_PROMO_CODE_SUCCESS = 'GET_PROMO_CODE_SUCCESS';
export const GET_PROMO_CODE_FAILURE = 'GET_PROMO_CODE_FAILURE';

export const GET_REFERRAL_CODE_REQUEST = 'GET_REFERRAL_CODE_REQUEST';
export const GET_REFERRAL_CODE_SUCCESS = 'GET_REFERRAL_CODE_SUCCESS';
export const GET_REFERRAL_CODE_FAILURE = 'GET_REFERRAL_CODE_FAILURE';

export const CREATE_REFERRAL_CODE_REQUEST = 'CREATE_REFERRAL_CODE_REQUEST';
export const CREATE_REFERRAL_CODE_SUCCESS = 'CREATE_REFERRAL_CODE_SUCCESS';
export const CREATE_REFERRAL_CODE_FAILURE = 'CREATE_REFERRAL_CODE_FAILURE';

export const APPLY_PROMO_CODE_REQUEST = 'APPLY_PROMO_CODE_REQUEST';
export const APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS';
export const APPLY_PROMO_CODE_FAILURE = 'APPLY_PROMO_CODE_FAILURE';

export const REMOVE_PROMO_CODE_REQUEST = 'REMOVE_PROMO_CODE_REQUEST';
export const REMOVE_PROMO_CODE_SUCCESS = 'REMOVE_PROMO_CODE_SUCCESS';
export const REMOVE_PROMO_CODE_FAILURE = 'REMOVE_PROMO_CODE_FAILURE';

export const ADD_TO_CART_PRODUCT = 'ADD_TO_CART_PRODUCT';
export const PRODUCT_INCREMENT = 'PRODUCT_INCREMENT';
export const PRODUCT_DECREMENT = 'PRODUCT_DECREMENT';
export const PRODUCT_CLEAR = 'PRODUCT_CLEAR';

export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

export const USER_ORDER_HISTORY_REQUEST = 'USER_ORDER_HISTORY_REQUEST';
export const USER_ORDER_HISTORY_SUCCESS = 'USER_ORDER_HISTORY_SUCCESS';
export const USER_ORDER_HISTORY_FAILURE = 'USER_ORDER_HISTORY_FAILURE';

export const GET_USER_ADDRESS_REQUEST = 'GET_USER_ADDRESS_REQUEST';
export const GET_USER_ADDRESS_SUCCESS = 'GET_USER_ADDRESS_SUCCESS';
export const GET_USER_ADDRESS_FAILURE = 'GET_USER_ADDRESS_FAILURE';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';

export const CUSTOMER_SUPPORT_REQUEST = 'CUSTOMER_SUPPORT_REQUEST';
export const CUSTOMER_SUPPORT_SUCCESS = 'CUSTOMER_SUPPORT_SUCCESS';
export const CUSTOMER_SUPPORT_FAILURE = 'CUSTOMER_SUPPORT_FAILURE';

export const ADD_USER_ADDRESS_REQUEST = 'ADD_USER_ADDRESS_REQUEST';
export const ADD_USER_ADDRESS_SUCCESS = 'ADD_USER_ADDRESS_SUCCESS';
export const ADD_USER_ADDRESS_FAILURE = 'ADD_USER_ADDRESS_FAILURE';

export const SET_USER_ADDRESS_REQUEST = 'SET_USER_ADDRESS_REQUEST';
export const SET_USER_ADDRESS_SUCCESS = 'SET_USER_ADDRESS_SUCCESS';
export const SET_USER_ADDRESS_FAILURE = 'SET_USER_ADDRESS_FAILURE';

export const DELETE_USER_ADDRESS_REQUEST = 'DELETE_USER_ADDRESS_REQUEST';
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_FAILURE = 'DELETE_USER_ADDRESS_FAILURE';

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';

export const CUSTOMER_REVIEW_REQUEST = 'CUSTOMER_REVIEW_REQUEST';
export const CUSTOMER_REVIEW_SUCCESS = 'CUSTOMER_REVIEW_SUCCESS';
export const CUSTOMER_REVIEW_FAILURE = 'CUSTOMER_REVIEW_FAILURE';

export const GET_KITCHEN_OWNER_REVIEW_HISTORY_REQUEST = 'GET_KITCHEN_OWNER_REVIEW_HISTORY_REQUEST';
export const GET_KITCHEN_OWNER_REVIEW_HISTORY_SUCCESS = 'GET_KITCHEN_OWNER_REVIEW_HISTORY_SUCCESS';
export const GET_KITCHEN_OWNER_REVIEW_HISTORY_FAILURE = 'GET_KITCHEN_OWNER_REVIEW_HISTORY_FAILURE';

export const REFRESH_TOKEN_REQUES = 'REFRESH_TOKEN_REQUES';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FILURE = 'REFRESH_TOKEN_FILURE';

export const GET_IN_TOUCH_REQUES = 'GET_IN_TOUCH_REQUES';
export const GET_IN_TOUCH_SUCCESS = 'GET_IN_TOUCH_SUCCESS';
export const GET_IN_TOUCH_FAILURE = 'GET_IN_TOUCH_FAILURE';
