import React, { useState } from 'react';
import './index.css';
import searchIcon from '../../assets/images/search-icon.png';
import customersupport_contactus from '../../assets/images/customersupport_contactus.png';
import tastesonwaysupport_contactus from '../../assets/images/tastesonwaysupport_contactus.png';
import merchantsupport_contactus from '../../assets/images/merchantsupport_contactus.png';
import { useDispatch, useSelector } from 'react-redux';
import { getInTouch } from '../../redux/actions/getInTouch/getInTouch';
import { toast } from 'react-toastify';

const ContactUs = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { userInfo } = useSelector((state) => ({
        userInfo: state?.userInfo?.payload?.data,
    }));

    function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        const formData = new FormData(event.target);
        const formValues = {};
        for (let [name, value] of formData) {
            formValues[name] = value;
        }

        const data = {
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            email: formValues.email,
            number: formValues.phoneNumber,
            message: formValues.message,
        };

        dispatch(getInTouch(data)).then((res) => {
            if (res.status === 200) {
                toast.success('your message sent success');
                event.target.reset();
                setIsLoading(false);
            }
        });
    }
    return (
        <>
            <div className="ContactUsPage">
                <div className="container">
                    <div className="row">
                        <div className=" col-lg-12 CoverHeadAbout">
                            <h1>How can we help You?</h1>

                            <div className="mt-5">
                                <div className="input-group NewWidthInput">
                                    <input
                                        type="text"
                                        className="form-control InputTypeSearch"
                                        id="inlineFormInputGroupUsername"
                                        placeholder="Search"
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text VisaImgContact">
                                            <img
                                                src={searchIcon}
                                                className="ImgSearchIcon"
                                                alt="search-icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row TopMargin">
                    <div className="col-sm-12 col-md-4 col-md-4">
                        <div className="card BoxCard ContactUSBox" style={{ width: '18rem' }}>
                            <img
                                src={customersupport_contactus}
                                className="card-img-top BoxImg"
                                alt="become a dasher"
                            />
                            <div className="card-body BoxTextCenter">
                                <h5 className="card-title TextColor">Customer Support</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-md-4 TopBoxmargin">
                        <div className="card BoxCard ContactUSBox" style={{ width: '18rem' }}>
                            <img
                                src={tastesonwaysupport_contactus}
                                className="card-img-top BoxImg"
                                alt="become a dasher"
                            />
                            <div className="card-body BoxTextCenter">
                                <h5 className="card-title TextColor">Tastes on way Support</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-md-4 TopBoxmargin">
                        <div className="card BoxCard ContactUSBox" style={{ width: '18rem' }}>
                            <img
                                src={merchantsupport_contactus}
                                className="card-img-top BoxImg"
                                alt="become a dasher"
                            />
                            <div className="card-body BoxTextCenter">
                                <h5 className="card-title TextColor">Merchant Support</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid HomeBackWhite">
                <div className="container">
                    <div className="row TopMargin ContactPaddingHome">
                        <h2>Get In Touch</h2>
                    </div>
                    <div className="row ContactPaddingHomebottom">
                        <div className="Boxpmdiv">
                            <form className="mt-3" onSubmit={handleSubmit}>
                                <div className="form-row mt-4 TextColor">
                                    <div className="col-lg-6">
                                        <label htmlFor="inputEmail4" className="ContactUsFont">
                                            First Name :
                                        </label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control ContactFormControl"
                                            id=""
                                            name="firstName"
                                            defaultValue={
                                                userInfo?.name.split(' ')[0]
                                                    ? userInfo?.name.split(' ')[0].toUpperCase()
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="inputEmail4" className="ContactUsFont">
                                            Last Name :
                                        </label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control ContactFormControl"
                                            id=""
                                            name="lastName"
                                            defaultValue={
                                                userInfo?.name.split(' ')[1]
                                                    ? userInfo?.name.split(' ')[1].toUpperCase()
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="form-row mt-4 TextColor">
                                    <div className="col-lg-6">
                                        <label htmlFor="inputEmail4" className="ContactUsFont">
                                            Email :
                                        </label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control ContactFormControl"
                                            id=""
                                            name="email"
                                            defaultValue={userInfo?.email}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="inputEmail4" className="ContactUsFont">
                                            Phone Number :
                                        </label>
                                        <input
                                            required
                                            type="number"
                                            className="form-control ContactFormControl"
                                            id=""
                                            name="phoneNumber"
                                            maxLength={10}
                                            defaultValue={userInfo?.mobile_number}
                                        />
                                    </div>
                                </div>

                                <div className="form-row mt-4 TextColor">
                                    <div className="col-lg-12">
                                        <label htmlFor="inputEmail4" className="ContactUsFont">
                                            Message :
                                        </label>
                                        <textarea
                                            required
                                            className="form-control MsgTxtArea"
                                            id="exampleFormControlTextarea1"
                                            rows={6}
                                            name="message"
                                        />
                                    </div>
                                </div>

                                <div className="form-row mt-4">
                                    <div className="col">
                                        {isLoading ? (
                                            <div>
                                                <div className="loader1 BtnDeliverHere">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-danger BtnDeliverHere BtnSent"
                                            >
                                                Sent
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
